<template>
    
  <div class="mb-4">
  <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #0D302A;">
    <div class="container-fluid align-items-end">
      <a class="navbar-brand v-step-0" @click="$router.push({name: 'home'})" style="cursor:pointer">
        <img src="../assets/images/logo/logo.svg" alt="" width="225" class="d-inline-block align-text-top">
      </a>

      <h5 class="text-light text-center mb-0">
        {{title}}
      </h5>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="!hideLinks" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-if="callStatusStore.call_status === 'call'" data-bs-toggle="offcanvas" href="#offcanvasVideoCall" role="button">
            <VTooltip>
              <a class="btn btn-outline-warning fs-5 position-relative" style="cursor: pointer"><i class="bi bi-webcam-fill"></i> Call in progress...
                <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-warning">
                  <span class="spinner-grow spinner-grow-sm"/>
                </span>
              </a>
              <template #popper>
                Go to video call meeting 
              </template>
            </VTooltip>
          </li>
          <li class="nav-item" v-if="interactiveStatusStore.status === 'active' && callStatusStore.call_status !== 'call'">
            <VTooltip>
              <button class="btn btn-outline-warning fs-5 position-relative" style="cursor: pointer" @click="$router.push({'name': 'training_room', 'params': {session_name: interactiveStatusStore.session_name}})"><i class="bi bi-webcam-fill"></i> Session in progress...
                <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-warning">
                  <span class="spinner-grow spinner-grow-sm"/>
                </span>
              </button>
              <template #popper>
                Go to interactive session
              </template>
            </VTooltip>
          </li>
          <li v-if="userCategory == 'slp' && (currentIndex == 'patient' || currentIndex == 'home' || currentIndex == 'settings' || currentIndex == 'subscription_page') && view_mode != 'demo'" class="nav-item">
            <a :class="[{'active':currentIndex == 'home'}, 'nav-link fs-5']" style="cursor: pointer; " @click="handleSelect('home')"><i class="bi bi-card-list"></i> Patient List</a>
          </li>
          <li v-if="enableBack" class="nav-item">
            <a :class="['nav-link fs-5']" style="cursor: pointer" @click="this.$router.back();"><i class="bi-arrow-return-left"></i> Go Back</a>
          </li>
          <li v-if="userCategory == 'patient' || (userCategory == 'slp' && view_mode == 'demo')" class="nav-item v-step-9">
            <a :class="[{'active':currentIndex == 'home'}, 'nav-link fs-5']" style="cursor: pointer" @click="handleSelect('home')"><i class="bi bi-house-door"></i> Home</a>
          </li>
          <li v-if="userCategory == 'slp' && currentIndex == 'patient'" class="nav-item v-step-9">
            <a :class="[{'active':currentIndex == 'patient'}, 'nav-link fs-5']" style="cursor: pointer" @click="handleSelect('patient')"><i class="bi bi-person-badge"></i> Patient</a>
          </li>
          <li class="nav-item v-step-8">
            <a :class="[{'active':currentIndex == 'settings'}, 'nav-link fs-5']" style="cursor: pointer" @click="handleSelect('settings')"><i class="bi bi-gear"></i> Settings</a>
          </li>
          <!-- <li v-if="deploy_type=='stage'" class="nav-item">
            <a :class="[{'active':currentIndex == 'dev'}, 'nav-link fs-5']" style="cursor: pointer" @click="handleSelect(4)"><i class="bi bi-gear"></i> Development</a>
          </li> -->

          <li class="nav-item">
            <a :class="['nav-link fs-5 me-2']" style="cursor: pointer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasContact"><i class="bi bi-question-square"></i> Help</a>
          </li>

          <li class="nav-item">
            <a :class="['nav-link fs-5']" style="cursor: pointer" data-bs-toggle="modal" data-bs-target="#signOutModal"><i class="bi bi-box-arrow-in-right"></i> Sign out</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <Contact/>

  <!-- MODAL -->
  <div class="modal fade" id="signOutModal" aria-labelledby="signout_modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        </div>
        <div class="modal-body">
          Are you sure that you would like to sign out?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="handleSelect('logout')">Yes, sign out</button>
        </div>
      </div>
    </div>
  </div>
  
  <Notification :userCategory="userCategory"/>
  </div>
</template>

<script>

import {logOut} from '@/firebase/index'
import Contact from '@/views/components/Contact.vue'
import Notification from '@/views/components/Notification.vue'

import { useCallStatusStore } from '@/stores/callStatus'
import { mapStores } from 'pinia'

import { useInteractiveStatusStore } from '@/stores/interactiveSessionStatus'


export default {
  props: {
      title: '',
      currentIndex: '',
      hideLinks: {
        type: Boolean,
        default: false
      },
      enableBack: {
        type: Boolean,
        default: false
      },
  },
  components: {
      Contact,
      Notification
  },
  data() {
    return {
      showContact: false,
      deploy_type: import.meta.env.VITE_DEPLOYMENT_TYPE,
      interactiveStatusStore: useInteractiveStatusStore(),
      userCategory: localStorage.getItem('userCategory'),
      view_mode: ''
    }
  },
  computed: {
  ...mapStores(useCallStatusStore),
  },
  created() {
    let _this = this

    _this.$firebase.auth().onAuthStateChanged((user) => {
      if (user) {
          
      } else {
        _this.$router.push({name: "signIn"});
      }

    });
  },
  mounted() {
    this.view_mode = localStorage.getItem('view_mode')
  },
  methods: {
    handleSelect(key) {
      
      if (this.interactiveStatusStore.status === 'active') {
        if (this.userType === 'slp') {
          //alert("You need to end the session with your current patient before starting a different session.")
          //return
        }
      }
      if (key == 'home') {
        if (this.userCategory == 'slp' && this.view_mode == 'demo') {
          this.$router.push({ name: 'client_demo' });
        } else {
          this.$router.push({ name: 'home' });
        }
      }

      if (key == 'settings') {
          this.$router.push({ name: 'settings' });
      }

      if (key == 'patient') {
        let clientUID = localStorage.getItem('client_uid');
        this.$router.push({ name: 'client_interface', query: {uid: clientUID} });
      }

      if(key=='dev')
      {
        this.$router.push({path:'development/image_search_selection_tool'})
      }

      if(key  == 'logout')
      {
        logOut();
      }

      //this.$emit('response', key)
    },
  }
}
</script>
<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}
</style>