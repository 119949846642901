<template>
  <div>

    <Layout>
      <template v-slot:content>
        <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->

        <div class="row">
          <div class="col-md-6">
            <div class="card card-body">
              <div class="row">
                <p class="text-30">
                  Press Start. Chat with the {{ bot_role }} <br/>
                  Once you are done, press Next.
                </p>
              </div>
              <div class="row">
                <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"   style="margin: auto; width: auto; height:400px; object-fit: contain"> 
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card card-body h-100">
                <div v-if="last_message!=''" class="d-flex justify-content-end">

          <div class="card p-3" style="background-color: #e8ffe8; border-left: 5px solid green; border-radius: 20px 20px 0 20px; max-width: 75%;">

            <p class="h5" style="white-space: pre-wrap; margin: 0;">
              {{ last_message }}
            </p>
          </div>
      </div>
      <br>
      <div class="col-6">
        <canvas id="meter" height="30"></canvas>
      </div>
      <br>

      <!-- Right Column for User's Dialogue -->
        <div class="d-flex justify-content-start mb-3">
          <div class="card p-3" style="background-color: #f0f0ff; border-left: 5px solid purple; border-radius: 20px 20px 20px 0; max-width: 75%;">
            <p class="h5" style="white-space: pre-wrap; margin: 0;">
              {{ last_reply }}
            </p>
        </div>
        </div>
            
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"

          :has_show_results="has_results"
          @show_results="goToResults()"   
          >
          <template v-slot:top>
            <hr/>
            <!-- <p class="lead" font-size="medium">Task {{curr_task+1}} of {{n_tasks}}</p> -->
            <div v-if="recognition_setting==0">
              <button v-if="!started" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()"><strong>{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></strong></button>
              <!-- <button v-if="started" class="btn btn-primary btn-lg w-100 mt-2" disabled>
                <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
              </button> -->
              <button v-if="started" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">Pause <i class="bi bi-pause-circle" style="margin-left: 8mm;"></i></button>

              <!-- <button v-if="started && congatulationsOnce" disabled class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button> -->
            </div>
            <!-- next was here -->
          </template>
        </Sidebar>
      </template>
    </Layout>

  </div>
</template>

<script>

import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import SpeechRec from '@/media_input/SpeechRecognition'
import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import { save_pre_computed_results } from '@/utils/results/data_save_utils'
import { sendMessage } from '@/utils/chatbot'


import {roundNumber,averageHighestPercentile} from '@/utils/utils'


import { checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'


import { logActivity, removePunctuationFromString } from '@/utils/utils'
import { useTTS } from '@/stores/tts'

import {BV_Points} from '@/utils/bv_points'


export default {
    name: 'chat_scenario',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: { Layout, Sidebar },
    data() {
      return {
        loudness:[],
        avg_loudness:0,
        avg_loudness_show:0,
        max_loudness:0,
        canvasContext: null,
        counter_loudness:0,
        curr_message:'',
        last_message:'',
        stopped:false,
        last_reply:'',
        chat_history:'',
        conversationHistory:[],
        total_exercises:1,
        recognition_setting: 0,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        diffMarks:{
          0:{
            style:{color: '#000000'},
            label:'Auto'
          },
          1:{
            style:{color: '#000000'},
            label:'Manual'
          },
        },
        answer_text: '',
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        bot_topic:'Barista',
        bot_role:'Barista',
        bot_topic_options:{
          'Postman':
          {
            "img_names": [
              "/BV/images/Chat_scenario/Postman/postman_1.png"
            ],
            "initial_message": "Hi! I'm Chris, your postman. How can I help you?",
            "role": "a Postman, named chris"
          },
          'Barista':
          {
            "img_names": [
                "/BV/images/Chat_scenario/Cafe/cafe_1.png"
              ],
              "initial_message": "Welcome to our coffee shop. What would you like to have?",
            "role": "a barista in the UK, named chris"
          },
          'Waiter':
          {
            "img_names": [
              "/BV/images/Chat_scenario/Restaurant/restaurant_1.png",
              "/BV/images/Chat_scenario/Restaurant/restaurant_2.png"
            ],
            "initial_message": "Good evening, I'm Chris, your waiter for today. How may I help you?",
            "role": "a restaurant waiter, named chris"
          },
          'Hairdresser':
          {
            "img_names": [
              "/BV/images/Chat_scenario/Hairdresser/hairdresser_1.png"
            ],
            "initial_message": "Welcome, how would you like to have your haircut",
            "role": "a hairdresser, named chris"

          }
        },
        initial_message:'',
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        has_results:false,
        target_word: '',
        single_target_word: '',
        audio_player: null,
        instructions_msg: '',
        crossImg: crossImg,
        doneIcon: doneIcon,
        play_pause_text: 'Start',
        current_exercise_index: 0,
        speech_rec: null,
        started: false,
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: localStorage.getItem('uid'),
        userType: localStorage.getItem('userCategory'),
        region: localStorage.getItem('region'),
        resultsTable: [],
        backImg:null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
      }
    },
    created() {

      const _this = this

      checkPermissionsMedia(); 

      logActivity( 'accessed', 'chat_scenario')

      this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))

      this.setup();

    },
    mounted()
    {
      const _this = this

      console.debug('mounted')


    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()
      this.speech_rec.stop()

      clearTimeout(window.countDownTimer)
      clearTimeout(window.countDownTimer2)
      clearTimeout(window.refreshIntervalId)

      deleteSoundMeter()


      stopRecordingMedia(null,null,null);


    },
    computed: {

    currentSentence_found: async function () {
      

      console.debug('this.currentSentence')
      console.debug(this.currentSentence)

      if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
      {



        let curr_message = (' ' + this.currentSentence).slice(1);

        this.sendMessage(curr_message)

        this.currentSentence = ''

        // this.stop_recording()
        // this.start_recording()


      }



    },
    },
    
    methods: 
    {


      saveResults()
      {
        const _this = this

        let loudness = 0
        console.debug('loudness')

        console.debug(this.loudness)

        if(this.loudness.length>10)
        {
          
          loudness = averageHighestPercentile(this.loudness,15)
        }


        console.debug(this.bot_topic)


        let results = {'bot_topic':this.bot_role,'conversationHistory':this.conversationHistory,'avg_loudness_array':[loudness]}
        var save_data = {resultsTablePre: {exercise_type: 'Chat Scenario', exercise: 'Chat Scenario', results: results, audio_address:''}}


        save_pre_computed_results(_this.exForm, save_data)

        console.debug('save_pre_computed_results finished')

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', save_data);
        
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      async initialMessage(initial_message)
      {
        let reply  = initial_message

        if(initial_message=='')
        {
          let curr_message = 'Shortly introduce yourself like a human would'
          this.conversationHistory.push({ role: 'user', content: curr_message }); // Add user input to conversation history

          reply = await sendMessage(this.conversationHistory,this.bot_topic_options[this.bot_topic]['role'])

          
        }


      
        this.conversationHistory.push({ role: 'assistant', content: reply }); // Add assistant's reply to history

        this.bv_tts.say(reply) 

        this.chat_history = this.chat_history + '\n' + reply

        this.last_reply = reply


      },
      async sendMessage(curr_message)
      {
        
        const _this = this

        this.bv_tts.cancel()

        this.conversationHistory.push({ role: 'user', content: curr_message }); // Add user input to conversation history
        let reply = await sendMessage(this.conversationHistory,this.bot_topic_options[this.bot_topic]['role'])

        this.conversationHistory.push({ role: 'assistant', content: reply }); // Add assistant's reply to history

        // console.debug('reply = ' + reply)

        console.debug(curr_message)
        this.bv_tts.say(reply) 

        this.last_message = curr_message

        this.last_reply = reply


        this.chat_history = this.chat_history + '\n' + curr_message

        this.chat_history = this.chat_history + '\n' + reply

        // this.curr_message = ''

      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        _this.$emit('goToResults', false);
      },
      setup()
      {
        console.debug('setup')
        let _this = this;

        _this.answer_text = '';

        _this.has_results = _this.exForm.has_results

        let img_array = []

        console.debug('this.exForm')

        console.debug(this.exForm)

        if('topic' in this.exForm)
        {

          for(let k in this.exForm.topic)
          {

            this.bot_topic = k
            this.bot_topic_options = this.exForm.topic

            this.bot_role = this.exForm.topic[k].bot_role

            img_array = this.bot_topic_options[k]['img_names']
            this.initial_message = this.bot_topic_options[k]['initial_message']

            
          }
          
        }
        else
        {
          this.bot_topic = localStorage.getItem('chatbot_topic')

          this.bot_role = this.bot_topic

          img_array = this.bot_topic_options[this.bot_topic]['img_names']

          this.initial_message = this.bot_topic_options[this.bot_topic]['initial_message']

        }




        this.bot_topic_options[this.bot_topic]['role'] = this.bot_topic_options[this.bot_topic]['role'] + ". Assume the location is in the UK." 
        
        console.debug('client_demo= '+_this.client_demo);


        this.bot_topic_options[this.bot_topic]['role'] = this.bot_topic_options[this.bot_topic]['role'] + ". Assume the location is in the UK." 

        

        let img_address = img_array[[Math.floor(img_array.length * Math.random())]]

        console.debug('img_address = ' + img_address)
        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
          console.debug('url = ' )
          console.debug(url)
            _this.imgSrc = url;
        }).catch(function(error) {

        });




        
        _this.initialMessage(this.initial_message)

       
        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.current_exercise_index = _this.exForm.current_exercise_index + 1


        console.log('_this.exForm.current_exercise_index = ' + _this.exForm.current_exercise_index)
        _this.total_exercises = _this.exForm.total_all_type_exercises


    
      },
      playPause()
      {
        let _this = this;

        if(this.started)
        {
          _this.play_status = false
          _this.started = false
          _this.speech_rec.stop()
          
        }
        else
        {
          _this.play_status = true;
        _this.resumeRecording()
        _this.results['total'] = 1

        }

      },
      resumeRecording()
      {
        let _this = this;
        // recordAudioMedia()
        _this.is_recording = true;
        _this.congatulationsOnce = false;
        // _this.runTimer()
        _this.start_recording()

        _this.runTimer()

      },
      runTimer() {

        let interval_time = 50;

        const _this = this;

        clearInterval(window.refreshIntervalId)

        window.refreshIntervalId = setInterval(function () {


          if (soundMeter != null) {

              // if(element!=null)
              {

                // var sound_l = soundMeter.instant.toFixed(2) ;
                var sound_l = Number(soundMeter.db.toFixed(2));
                let sound_l2 = soundMeter.db2.toFixed(2);
                var max_freq_index = soundMeter.max_freq_index

                if(max_freq_index==undefined || max_freq_index==null)
                {
                  max_freq_index = 0
                }
                
                max_freq_index = max_freq_index.toFixed(2);

                // let sound_calibration = 20;
                let sound_threshold = 53;
                
                // console.debug('sound_l = ' + sound_l)
                if(sound_l>sound_threshold)
                {               
                  _this.loudness.push(sound_l)   
                  _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                  
                  _this.avg_loudness_show = roundNumber(_this.avg_loudness,0)

                  _this.max_loudness = Math.max(_this.max_loudness,sound_l)

                //   console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)
                _this.counter_loudness += 1;
              }


             

              {
                let element = document.getElementById("meter")
                if(element!=undefined && element!=null)
                {
                  _this.canvasContext = element.getContext("2d");
                  _this.drawLoop(sound_l);
                }
              }

            
            }
          }
        }, interval_time);


      },
      drawLoop(sound_l) {
        // clear the background
  
        var _this = this;
  
        var WIDTH = 400;//document.getElementById('meter').clientWidth;
        var HEIGHT = 30;//document.getElementById('meter').clientHeight;;
  
        var green_threshold = 50;
  
        // console.debug("Width = " + WIDTH);
        
        // console.debug("HEIGHT = " + HEIGHT);
  
  
        if(_this.canvasContext != undefined && _this.canvasContext !=null)
        {
          _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
        
          if (sound_l > green_threshold) {
            _this.canvasContext.fillStyle = "green";
          } else {
            // _this.silence_flag = true;  
            _this.canvasContext.fillStyle = "red";
          }
          _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
          // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);
        }
        // draw a bar based on the current volume  
      },  


      start_recording() {
        const _this = this
        this.bv_tts.cancel()

        recordAudioMedia()


        this.speech_rec.start(undefined,true,this.region)
        this.started = true
        // this.play_pause_text = 'Recording'
        console.debug('started')

        clearTimeout(window.countDownTimer)

        clearTimeout(window.countDownTimer2)
        
        // window.countDownTimer2 = setTimeout(() => {
        //   _this.answer_text = 'It is recording now, name the picture out loud'
        //   _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        // }, 500)
          
        window.countDownTimer = setTimeout(() => {
          if(_this.play_status)
          {
            _this.started = false
            _this.play_pause_text = 'Start'

            _this.answer_text = "Oops we missed what you said! Press Start and try again"
            _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
          }
          _this.stop_recording()
        }, 1000000)
      },
      stop_recording() {
        const _this = this
        this.stopped = true
        _this.play_status = false;
        this.started = false
        _this.currentSentence = ''
        _this.speech_rec.stop()

        stopRecordingMedia(null,null,null);
      },
      pause()
      {
        let _this = this;
        _this.play_status = false;
        _this.play_pause_text = 'Start'
      },
    }
}
</script>

<style scoped>
.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 35mm;
}
</style>
