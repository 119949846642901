<template>
  <div class="row mt-2">
    

    <div class="card">


 
      <div v-if="'DIP' in q_data">

      <div class="col">
        <h4>Dysarthria Impact Profile</h4>
      </div>


      <Single_DIP
            :q_data="q_data['DIP']">
        </Single_DIP>
        
      </div>

        </div>
        </div>

</template>

<script>
import { ref, onUpdated, computed, onMounted } from "vue";



import Single_DIP from "../../../dashboard/slt/client_interface/pages/case_notes/components/dip/single_dip.vue";


export default {
  name: "selection_scales",
  components:{

    Single_DIP
  },
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
  methods: {
   
  },
  setup(props) {
    

    return {
    };
  },
};
</script>

<style>

</style>
