<template>
<div>

  <div v-show="show_results" >
    <Layout>
      <template v-slot:content>
        <div class="row"> 
          <p class="h3"> Press Next to go to the next exercise or press Repeat to do it again. </p>                
        </div>

        <div v-if="playback_audio" class="card card-body h-100">
          <p class="h3"> {{Ex_Text}}</p>
        </div>
        <div v-else class="card card-body h-100">
          <div class="text-center">
            <img class="text-center" :src="memeImg" width="425" height="325" style="object-fit: contain"><h1 class="carousel-caption">Well done!</h1>
          </div>        
        </div>

        <div class="card card-body h-100 justify-content-center">
          <p class="h4" v-if="last_loudness>0"> Loudness: {{ last_loudness }} dB</p>
          <p class="h4" v-if="freq_range>0"> Frequency: {{ freq_range }} Hz, from {{ min_freq }} Hz to {{ max_freq }} Hz </p>
          <div class="text-center">
            <button  class='btn btn-outline-warning w-50 btn-lg mt-4' @click="goToResults()">See your results</button>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>

        <Sidebar
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises">
          <template v-slot:top>
            <div v-if="audioCaptureOn">
              <button class="btn-info btn btn-lg w-100 mt-2" @click="nextExercise()"> Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
              <button class='btn btn-success w-100 btn-lg mt-2' @click="repeatExerciseModal()">Repeat <i class="bi bi-arrow-clockwise" style="margin-left: 8mm;"></i> </button>
              <button class="btn-primary btn btn-lg w-100 mt-2 " @click="getBufferCallback()"> Listen back <i class="bi bi-ear" style="margin-left: 3mm;"></i></button>
            </div>
            <div v-else>
              <button class="btn btn-info btn-lg w-100" @click="noAudioCaptureNext()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
            </div>
            <div style="margin-top: 20mm;">
              <div class="row">
                <i class="bi bi-sign-stop" style="color: #f56c6c;font-size: 80px;"></i>
                <p> {{ stop_sign_text }}</p>
              </div>
            </div>
          </template>
        </Sidebar>
      </template>
    </Layout>
  </div>
  <div  v-show="!show_results">
    <Layout>
      <template v-slot:content>
        <div class="row"> 
          <p class="lead" style="font-size: 30px;"> {{Ex_Description}}</p>
        </div>
        <div class="row">
          <div class="card card-body" v-if="Ex_Text!==''" >
            <div class="row">
              <div class="col-12">
        
                <p class="h3">{{Ex_Text}} 
                
                  <VTooltip>
                    <img v-if="tts_enabled" :src="audioImg" style="width:35px;cursor: pointer;" @click="readContentAloud()">
                    <template #popper>
                      Click to listen
                    </template>
                  </VTooltip>
                </p>
              </div>
            </div>
            
          </div> 
          <div class="row mt-2 justify-content-center align-items-center">
            <div class="col-6">
              <!-- <div class="parent"> -->
                <img class="backimg" :src="backImg" style="width: 100%; object-fit:cover">
                <!-- <img class="frontimg" :src="frontImg" style="width: 40%; object-fit:cover"> -->
              <!-- </div> -->
              
            </div>
            <div id="SPL-div" class="col-6">
              <canvas ref="loud_canvas" id="loud_canvas" width="400" height="180"></canvas>
              <p>Your loudness graph <button type="button" class="btn btn-outline-success" @click="graph_instruction('loudness')">How to use <i class="bi bi-info-circle"></i></button></p>
            </div>
          </div>
        </div>



        <div class="row mt-4"> 
          <div class="row" v-if="hasVideoInstructions || hasAudioInstructions" id="video_instructions_div">
            <div id="video_placeholder"></div>
          </div>
        
          <div class="row justify-content-center" v-if="!(hasSPLMeter||hasSpectrogram)" id="meter-row" >
            <div class="col-6">
              <canvas id="meter" height="30"></canvas>
            </div>
          </div>
          
          <!-- <div id="SPL-div" class="row justify-content-center align-items-center">
            
            <div class="col-md-6">
              <canvas ref="loud_canvas" id="loud_canvas" width="400" height="180"></canvas>
              <p>Your loudness graph <button type="button" class="btn btn-outline-success" @click="graph_instruction('loudness')">How to use <i class="bi bi-info-circle"></i></button></p>
            </div>
  
          </div> -->
        </div>
        <div class="modal fade" id="demo_video_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Therapist demo</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-around">
                  <div class="col-md-10">
                    <div class="card card-body h-100">
                      <div class="row" id="video_demo_instructions_div">
                        <div id="video_demo_placeholder"></div>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-2">

                    <div class="card card-body h-100">
                      <p class="lead">Demo {{demo_video_index+1}} of {{demo_video_pool.length}}</p>

                      <button v-if="demo_video_index<(demo_video_pool.length-1)" class="btn btn-info w-100 mt-2" @click="nextDemoVideo()" >Next <i class="bi bi-skip-forward" style="margin-left: 4mm;"></i> </button>

                      <button class="btn btn-secondary w-100 mt-2"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                  </div>

                </div>                  
              </div>
            </div>
          </div>
        </div>
        
        <div class="modal fade" id="graph_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Instructions - {{ graph_type }} graph</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-if="graph_type === 'loudness'">
                  <div class="row justify-content-center">
                    <img :src="loudness_graph_img" style="width:800px;margin-top:0.5%;">
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-md-8">
                      <ol class="list-group list-group-numbered" style="text-align: left;font-size: x-large;">
                        <li class="list-group-item">The <b style="color:rgb(15, 105, 26)">green</b> line shows your voice volume as you speak.</li>
                        <li class="list-group-item">The <b style="color:#f56c6c">red</b> line shows your volume target. Try to get your voice to the red line (target) as you speak.</li>
                        <li class="list-group-item">You can change the volume target at any time in the 'Settings' tab.</li>
                        <li class="list-group-item">The 'Settings' tab can be found on the top right corner of the page.</li>
                      </ol>
                    </div>
                    
                  </div>
                </div>
                
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100"  data-bs-dismiss="modal" aria-label="Close">I understand</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="db_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Your loudness was:</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="row">
                    <h4 >{{ last_loudness }}</h4>

                  </div>
                  <div class="row">
                    <button class="btn btn-primary w-50"  style="padding-left: 10px"  @click="nextExercise()" data-bs-dismiss="modal" >Next exercise <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
                    <button class="btn btn-success w-50"  style="padding-left: 10px"  @click="repeatExerciseModal()" data-bs-dismiss="modal" >Repeat exercise <i class="bi bi-arrow-clockwise" style="margin-left: 3mm;"></i> </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises">
          <template v-slot:top>
            <div v-if="audioCaptureOn">
              <button v-bind:class="[play_pause_text===start_btn_text ? 'btn-primary' : 'btn-info', 'btn btn-lg w-100 mt-2']" @click="playPause()">{{play_pause_text}} <i v-bind:class="[play_pause_text===start_btn_text ? 'bi bi-play-circle' : 'bi bi-stop-circle']" style="margin-left: 8mm;"></i></button>
              <!-- <button class='btn btn-secondary btn-lg mt-2 w-100' @click="skipExercise()"> Skip <i class="bi bi-chevron-bar-right" style="margin-left: 8mm;"></i></button>       -->
            </div>
            <div v-else>
              <button class="btn btn-info btn-lg w-100" @click="noAudioCaptureNext()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
            </div>
            <div v-if="hasSPLMeter" style="margin-top:15% ;">
              <label class="form-label mt-2">Loudness target</label>
              <select class="form-select mb-3" v-model="loud_target" placeholder="Select" style="width:100%"  @change="updateChart()">
                <option key="2" label="60 dB - Quiet conversation" value=60> </option>
                <option key="3" label="65 dB - Normal conversation" value=65> </option>
                <option key="4" label="70 dB - Loud conversation" value=70> </option>
              </select>
            </div>

            <div v-show="audio_player!=null" class="form-check mt-4">
              <input class="form-check-input" type="checkbox"  id="flexCheckChecked" v-value="background_noise_checked" @click="stopBackgroundNoise()">
              <label class="form-check-label" for="flexCheckChecked">
                Background noise 
              </label>
            </div>

            <div v-if="demo_video_pool!=undefined && demo_video_pool!=null && demo_video_pool.length>0" style="margin-top: 10mm;">
              <button  class="btn btn-bd-yellow btn-lg w-100" @click="gotoVideoDemo()"> Therapist demo <i class="bi bi-person-video"></i></button>
            </div>
            <div>
              <div class="row">
                <i class="bi bi-sign-stop" style="color: #f56c6c;font-size: 80px;"></i>
                <p >{{ stop_sign_text }}</p>
              </div>
            </div>
          </template>
        </Sidebar>
      </template>
    </Layout>

  </div>

</div>
</template>
  
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import homeplanImg from '@/assets/images/homeplan.png'
import audioImg from '@/assets/images/audio_logo.png'
import constants from '@/utils/constants'
import loudness_graph_img from '@/assets/images/instructions/loudness_graph_instruction2.png'
import spectrogram_graph_img from '@/assets/images/spectrogram_graph_img.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import {get_last_recording, checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'
import smoothie from 'smoothie'
import {removeWeirdCharactersFromString,removeQuotesAndSimilarForAnalysis} from '@/utils/utils'
import {updateDataDirectory } from '@/firebase/index'
import {logActivity} from '@/utils/utils'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'
import {generate_results_path, save_pre_computed_results} from '@/utils/results/data_save_utils'
import { Modal } from 'bootstrap'


  export default {
      name: 'basic',
      props: {
        exForm: {
          type: Object,
        },
      },
      components: {Layout, Sidebar},
      data() {
        return {
          stop_sign_text:'Stop if your voice is strained',
          video_url:null,
          show_countdown: false,
          showPhonation:false,
          phonationOn:false,
          show_results:false,
          demo_video_pool:[],
          demo_video_index:0,
          memeImg:false,
          background_noise_checked:true,
          playback_audio:false,
          freq_range:0,
          max_freq:0,
          min_freq:0,
          frequency_data:[],
          progress: 0,
          maxdBValue:80,
          mindBValue:40,
          hasVideoInstructions: false,
          hasAudioInstructions: false,
          video_instructions_address: '',
          audio_instructions_address: '',
          audio_player:null,
          audio_player_text:'',
          audio_player_status:false,
          background_audio_location: null,
          background_audio_url:null,
          audioImg: audioImg,
          sound_l:'',
          sound_l2:'',
          chart_data: null,
          chart_data_spect: null,
          smoothie_spect: null,
          start_btn_text: 'Start',
          smoothie: null,
          drawInterval: null,
          Ex_Text: '',    
          Ex_Description: [],
          ex_name: '',
          max_loudness:0,
          avg_loudness: 0,
          avg_loudness_show: 0,
          hasSPLMeter: false,
          last_loudness: 0,
          total_loudness: 0,
          counter_loudness: 0,
          audioCaptureOn: true,
          hasSpectrogram: false,
          chart_not_created: true,
          chart_not_created_spect: true,
          canvasContext: null,
          tts_enabled: true,
          play_pause_text: 'Record',
          finished_msg: '',
          play_status: false,
          current_exercise_index: 0,
          total_exercises: 1,
          homeplanImg: homeplanImg,
          crossImg: crossImg,
          doneIcon: doneIcon,
          inputAcc:'',
          modules_results_path: null,
          resultsTableBack: [],
          is_recording: false,
          url:'',
          url2image:'',
          userType: 'patient',
          client_number: null,
          uid: '',
          loud_target:65,
          bv_tts: useTTS().tts_engine,
          bv_points: new BV_Points(),
          region:'UK',
          loudness_graph_img:loudness_graph_img,
          spectrogram_graph_img:spectrogram_graph_img,
          graph_type:'loudness',
          backImg:null,
          avatarImg:null

        }
      },
      created() {
        let _this = this;
        
        checkPermissionsMedia(); 
        _this.getExercise();

            
        let region = localStorage.getItem('region');
        
        if(region!=undefined && region!=null)
        {
          _this.region = region
        }

        
        logActivity( 'accessed', 'multi_word')
        console.debug('_this.exForm = '  + _this.exForm)
  
        console.debug('created basic exercise module')
        
        // var user = _this.$firebase.auth().currentUser;
        // let uid = user.uid;
  
        _this.userType = localStorage.getItem('userCategory');
  
        if(this.userType=='slp')
        {
            let client_number = localStorage.getItem('client_uid');
  
            if(client_number!='undefined' && client_number!=null && client_number!='')
            {
              _this.client_number = client_number; 
            }
        }
  
        let uid = localStorage.getItem('uid');
        _this.uid = uid;
        console.debug('uid = ' + uid)
          
      },
      computed: {
  
      Ex_Description_Split: function () {
        return this.Ex_Description.toString().split('\n')
      },
      Ex_Text_Split: function()
      {
  
        console.debug('this.Ex_Text = ' + this.Ex_Text)
  
       let split_text =  this.Ex_Text.toString().split('\n')
  
       console.debug('split_text = ' + split_text)
  
       console.debug('split_text.length = ' + split_text.length)
       
    
  
        return split_text
  
      },
  
    },
    beforeUnmount()
    {
        
        const _this = this

        this.bv_tts.cancel()


        clearInterval(window.refreshIntervalId)
        window.speechSynthesis.cancel()





        if(_this.is_recording)
        {

            _this.endRecordingAudio(true);
        }
        else
        {
            _this.endRecordingAudio(false);
        }

        deleteSoundMeter()


        if(_this.audio_player!=undefined && _this.audio_player!=null)
        {
          if(!_this.audio_player.paused)
          {
            _this.audio_player.pause();

          }
        }




    },
    mounted: function()
    {

      
      let loudness_target = localStorage.getItem('loudness_target')

      if(loudness_target!=undefined && loudness_target!=null)
      {
        loudness_target=parseInt(loudness_target)

        if(!isNaN(loudness_target))
        {
          this.loud_target = loudness_target
        }
      }


      this.renderGraphs()

      
      if('background_audio_location' in this.exForm)
      {

        let temp_background_noise_checked = sessionStorage.getItem("in_noise_background_noise")


        if(temp_background_noise_checked!=undefined && temp_background_noise_checked!=null)
        {
          if(temp_background_noise_checked==='false')
          {
            this.background_noise_checked = false

          }
          else
          {
            this.background_noise_checked = true

          }

        }
        else
        {
          this.background_noise_checked = true
        }

        let background_audio_location_array = this.exForm['background_audio_location']
        this.background_audio_location = background_audio_location_array[[Math.floor(background_audio_location_array.length * Math.random())]]

        // console.log('exform names = '+ this.exForm['img_names'])

        this.loadBackgroundAudio()
        let background_img_array = this.exForm['img_names']
        let Img_start = background_img_array[[Math.floor(background_img_array.length * Math.random())]]
        let backImg_file = Img_start+'_full.png'


        let folder_name_arr = Img_start.split("_")
        let folder_name = folder_name_arr[0]
          for(let cnt=1;cnt<folder_name_arr.length-1;cnt++)
          {
            folder_name = folder_name+'_'+folder_name_arr[cnt]
          }
        this.backImg = new URL(`../../../../../assets/images/conversation_scenes/${folder_name}/${backImg_file}`, import.meta.url)
      }

    
    },
    updated: function()
    {

    },
      methods: 
      {
        graph_instruction(type)
        {
          if(type == 'loudness')
          {
            this.graph_type = 'loudness'
          }
          else if(type == 'pitch')
          {
            this.graph_type = 'pitch'
          }
          else
          {
            console.log('unknown graph type = '+type)
          }
          let graph_pop = new Modal(document.getElementById('graph_pop'));
          graph_pop.show()
        },
        gotoVideoDemo()
        {
          const _this = this

          let demo_video_pop = new Modal(document.getElementById('demo_video_pop'));
          demo_video_pop.show()

          if(_this.audio_player!=undefined && _this.audio_player!=null)
          {
            if(!_this.audio_player.paused)
            {
              _this.audio_player.pause();

            }
          }


          this.demo_video_index = 0

          this.playDemoVideoInstructions()

          //to be programmed - for showing demo examples 1,2,3
        },
        nextDemoVideo()
        {
          this.demo_video_index = this.demo_video_index+1

          this.playDemoVideoInstructions()


        },
        playDemoVideoInstructions()
        {
          const _this = this

          let curr_video = this.demo_video_pool[this.demo_video_index]


          let demo_video_instructions_address = curr_video["Video_Address"]

          var storage = _this.$firebase.storage();
  
  
          storage.ref(demo_video_instructions_address).getDownloadURL()
            .then((video_url) => {
              document.getElementById('video_demo_placeholder').innerHTML='<video  id="movie_demo" src="'+video_url+'" height="380px" width="600px" controls autoplay> </video>';
              var player =document.getElementById('movie_demo');
              player.load();
              

              console.debug('here1')

              document.getElementById('demo_video_pop').addEventListener('hidden.bs.modal', function (event) {
                
                console.debug('here2')

                if(player!=undefined && player!=null)
                {
                  if(!player.paused)
                  {
                    player.pause();

                  }
                }  
                  // do something...
              })

    

            })
            .catch((error) => {
              // Handle any errors
            });          
        },
        getBufferCallback(  ) {

          this.playback_audio = true

          let blob = get_last_recording()


          var audio = new Audio(URL.createObjectURL(blob));

          audio.play()
         
        },
        renderGraphs()
        {
          const _this = this
              
          if(_this.hasSPLMeter)
          {
            document.getElementById("SPL-div").hidden = false
            
            _this.createChart()

  
          }
          else
          {
            document.getElementById("SPL-div").hidden = true
          }
  
  
        
  
        },
        openURL()
        {
          const _this = this
  
          
          window.open(_this.url);
  
  
        },
       
        getExercise()
        {
          const _this = this;
          
          if(_this.is_recording)
          {
              // _this.endRecordingAudio(true)
          }
  
          _this.pause();
  
          _this.loadSingleExercise()
  
  
        },
        repeatExerciseModal()
        {
          const _this = this
          _this.show_results = false
          let avg_loudness_array = []

          this.playback_audio = false

  
          this.exForm['random_identifier'] = this.exForm['random_identifier'] + "_" + Math.random().toString(16).slice(2)
  

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          if(_this.background_audio_url!=null)
          {
            _this.playBackgroundAudio()

          }

          _this.stopTimer()
        

          console.debug('avg_loudness_array = ' + avg_loudness_array)

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;
          _this.max_loudness = 0


          _this.play_status = false
  
          _this.play_pause_text = _this.start_btn_text


          if(_this.hasVideoInstructions)
          {
            _this.playVideoInstructions(_this.video_url);
          }
  

        },
        repeatExercise()
        {
          const _this = this

          if(_this.play_status)
          {
  

  
            _this.last_loudness = this.roundNumber(_this.avg_loudness,2)
  
            let avg_loudness_array = []
  
            if(_this.avg_loudness>0)
            {
              avg_loudness_array.push(_this.avg_loudness)
            }
  
  
            _this.avg_loudness = 0;
            _this.total_loudness = 0;
            _this.counter_loudness = 0;
  
  
  
            if(_this.is_recording)
            {
              _this.is_recording = false
  
              _this.endRecordingAudio(true)
  
            }
  
            _this.max_loudness = 0

            let resultsTableBack = []
            for(let i in _this.resultsTableBack)
            {
              resultsTableBack.push(_this.resultsTableBack[i])
  
            }

            if(_this.resultsTableBack.length>0)
            {
              var basicData = {resultsTableBack: resultsTableBack, avg_loudness_array: avg_loudness_array}
  
              _this.resultsTableBack = []
              
              _this.$emit('logExerciseResults', basicData);


            }
            else
            {
              var basicData = {resultsTableBack: null, avg_loudness_array: avg_loudness_array}
  
              _this.resultsTableBack = []
              
              _this.$emit('logExerciseResults', basicData);

            }
  
  
            _this.play_status = false
  
            _this.play_pause_text = _this.start_btn_text
  
            
            _this.stopTimer()


          }
  
        },
        stopTimer() {
          let _this = this


          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

          }
          _this.max_loudness = 0


          clearInterval(window.refreshIntervalId)
        }, 

        loadSingleExercise()
        {
            
          const _this = this

          _this.demo_video_pool = _this.exForm.demo_video_pool

  
  
  
        
          _this.Ex_Text = _this.exForm.text; 
        
          _this.tts_enabled = true

          let voicetype = _this.exForm.voicetype


          // this.exForm['background_audio_location'] = '/audio_resources/background_sounds/Small Restaurant Ambience.mp3'

  
          if(_this.Ex_Text==undefined || _this.Ex_Text==null)
          {
            _this.tts_enabled = false
          }
          else
          {
            let list_tts_fail_words = ['phoneme','/ah/','/eh/','/ih/','/oh/','/uh/']
  
            let ex_text_lower = _this.Ex_Text
            ex_text_lower = ex_text_lower.toLowerCase()
  
            for(let i in list_tts_fail_words)
            {
              if(ex_text_lower.includes(list_tts_fail_words[i]))
              {
                _this.tts_enabled = false
                break
              }
  
            }
          }



          
    


          if(_this.exForm.image_address!=undefined && _this.exForm.image_address!=null && _this.exForm.image_address!='')
          {
            _this.showimage(_this.exForm.image_address)
          }
  
          _this.Ex_Description = _this.exForm.instruct;
  
          _this.current_exercise_index = _this.exForm.current_exercise_index + 1
          _this.total_exercises = _this.exForm.total_all_type_exercises
  
          let url = _this.exForm.url
          let url2image = _this.exForm.url2image
  
          if(url!=undefined && url!=null)
          {
            _this.url = url
          }
  
          if(url2image!=undefined && url2image!=null)
          {
            _this.url2image = url2image
  
          }
  
  
          if(_this.exForm.start_btn_text!=undefined && _this.exForm.start_btn_text!=null)
          {
            _this.start_btn_text = _this.exForm.start_btn_text
            _this.play_pause_text = _this.start_btn_text
          }
  
  
  
  
          _this.Ex_Description = removeWeirdCharactersFromString(_this.Ex_Description)
          
          _this.Ex_Text = removeWeirdCharactersFromString(_this.Ex_Text)
  
          let ex_text_split = _this.Ex_Text.split(' ')
  

          if(_this.exForm.search_img!=undefined && _this.exForm.search_img!=null && _this.exForm.search_img!='')
          {
            _this.imgSearchWord(_this.Ex_Text)
          }
  
          if(_this.exForm.sound_autoplay!=undefined && _this.exForm.sound_autoplay!=null && _this.exForm.sound_autoplay==true)
          {
            setTimeout(() => {
              _this.readContentAloud()
            }, 750)
          }
          
          let captureAudio = _this.exForm.capture_audio
          if(captureAudio != undefined && captureAudio != null)
          {
            _this.audioCaptureOn = captureAudio
          }

          console.debug('_this.exForm.stop_sign_text = ' + _this.exForm.stop_sign_text)

          if(_this.exForm.stop_sign_text)
          {


            _this.stop_sign_text = _this.exForm.stop_sign_text

            console.debug('_this.stop_sign_text = ' + _this.stop_sign_text)

          }
          else
          {
            if(!_this.audioCaptureOn)
            {
              _this.stop_sign_text = 'Stop if your muscles are strained'
            }
          }

  
  
  
          if(_this.Ex_Text == undefined || _this.Ex_Text == null )
          {
              _this.Ex_Text = ""
          }
          if(_this.Ex_Description == undefined || _this.Ex_Description == null )
          {
              _this.Ex_Description = ""
          }
  
  
          _this.checkAudioVideoInstructions()
          _this.checkAssistance()
  
  
        },
        readContentAloud()
        {
          const _this = this


          if(_this.Ex_Text.length>200)
          {
            let array_text = _this.Ex_Text.split('.')

            this.bv_tts.say_sentences(array_text, 75)

          }
          else
          {
            _this.bv_tts.say(_this.Ex_Text)

          }

          

        },
        imgSearchWord(word)
        {
          const _this = this
        
  
          let CSE_ID = '0a00ce12c9207f97c'
          let API_KEY = 'AIzaSyAyaTunYyJEdu_QbzvBN_xMJYmRDMWB_sc'
  
  
          const Http = new XMLHttpRequest();
          const url='https://customsearch.googleapis.com/customsearch/v1?cx='+CSE_ID+'&key='+API_KEY+'&q='+word+'&searchType=image&imgType=clipart&page=1&num=1&fields=items(image/thumbnailLink)'
          //&imgType=clipart'
          console.debug(url)
          Http.open("GET", url);
          Http.send();
  
  
          Http.onreadystatechange = (e) => {
  
            let json_response = JSON.parse(Http.response)
  
            // console.debug(json_response['items'][0])
  
            if(json_response['items'].length>0)
            {
              _this.url2image = json_response['items'][0]['image']['thumbnailLink']
            }
            
  
          }
  
          // const imageSearch = require('image-search-google');
  
          // const client = new imageSearch(CSE_ID, API_KEY);
  
          // window.setImmediate = window.setTimeout
          // const options = {page:1,num:1};
  
  
  
          // client.search(word, options).then(images => {
  
          //   if(images!=undefined && images!=null && images.length>0)
          //   {
          //     _this.url2image = images[0]['thumbnail']
  
          //   }
  
  
          //   /*
          //   [{
          //       'url': item.link,
          //       'thumbnail':item.image.thumbnailLink,
          //       'snippet':item.title,
          //       'context': item.image.contextLink
          //   }]
          //   */
          // }).catch(error => console.debug('error = ' + error));
  
  
        },
        checkAudioVideoInstructions()
        {
          const _this = this
  
          console.debug('_this.exForm.videoIns = ' + _this.exForm.videoIns)
  
          let hasVideoInstructions = _this.exForm.videoIns;
  
          if(hasVideoInstructions!=undefined && hasVideoInstructions!=null)
          {
              _this.hasVideoInstructions = hasVideoInstructions;
          }
          else
          {
              _this.hasVideoInstructions = false;
  
              if(_this.exForm.Video_Address!=undefined && _this.exForm.Video_Address!=null)
              {
                _this.hasVideoInstructions = true
                _this.exForm.videoAddress = _this.exForm.Video_Address
              }
          }
  
  
          // let hasAudioInstructions = _this.exForm.audioIns;
  
          let audioIns = _this.exForm.audioIns
  
          if(audioIns!=undefined & audioIns!=null)
          {
              _this.hasAudioInstructions = true;
          }
          else
          {
              _this.hasAudioInstructions = false;
          }
  
          if(_this.hasVideoInstructions)
          {
              _this.video_instructions_address = _this.exForm.videoAddress;
  
              console.debug('_this.video_instructions_address = ' + _this.video_instructions_address)
              _this.watchVideoInstructions()
          }
          else if(_this.hasAudioInstructions)
          {
            _this.audio_instructions_address = audioIns
              // _this.audio_instructions_address = _this.exForm.audioAddress;
  
              _this.watchVideoInstructions()
          }
  
        },
        watchVideoInstructions()
        {
          const _this = this;
  
          if(_this.is_recording)
          {
            _this.is_recording = false
            _this.endRecordingAudio(false);
            _this.play_pause_text = _this.start_btn_text
          }
  
          
          if(_this.hasVideoInstructions)
          {
  
  
  
            _this.is_recording = false;
        
            var storage = _this.$firebase.storage();
  
  
            storage.ref(_this.video_instructions_address).getDownloadURL()
              .then((url) => {
                _this.video_url = url
                _this.playVideoInstructions(url);
  
              })
              .catch((error) => {
                // Handle any errors
              });
          }
          else if(_this.hasAudioInstructions)
          {
  
            // document.getElementById('ex_div').hidden = true;
  
            // document.getElementById('mic_dist_div').hidden = true;
  
            // document.getElementById('video_instructions_div').hidden = false;
  
            stopRecordingMedia(null,null,null);
            
            // clearInterval(_this.drawInterval);
            clearInterval(window.refreshIntervalId);
    
            _this.drawLoop(0);
  
            _this.is_recording = false;
        
            var storage = _this.$firebase.storage();
  
  
            storage.ref(_this.audio_instructions_address).getDownloadURL()
              .then((url) => {
                _this.playAudioInstructions(url);
  
              })
              .catch((error) => {
                // Handle any errors
              });
          }
        },
        playVideoInstructions(url)
        {      
  
          console.debug('url = ' + url)
          document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="380px" width="600px" controls autoplay> </video>';
          var player =document.getElementById('movie');
          player.load();
  
          
        },
        
        checkAssistance()
        {
          const _this = this
  
          let assistance = _this.exForm.assist
  
  
          _this.hasSPLMeter = false;
  
          if(assistance!=null)
          {
            console.debug('assistance = ' + assistance)
            
            if(Array.isArray(assistance) && assistance.includes('spm_live'))
            {
                _this.hasSPLMeter = true;
            }
            else if('spm_live' in assistance)
            {
                _this.hasSPLMeter = true;
  
            }
  
            if(Array.isArray(assistance) && assistance.includes('spl_live'))
            {
                _this.hasSPLMeter = true;
  
  
            }
            else if('spl_live' in assistance)
            {
                _this.hasSPLMeter = true;
  
            }
  
            if(Array.isArray(assistance) && assistance.includes('spectrogram'))
            {
                _this.hasSpectrogram = true;
  
  
            }
            else if('spl_live' in assistance)
            {
                _this.hasSpectrogram = true;
  
            }
  
  
  
          }
  
          // _this.hasSpectrogram = true
  
        },
        runTimer() {
      
  
          var interval_time = 250;
  
          const _this = this;
  
          clearInterval(window.refreshIntervalId)
          


          window.refreshIntervalId = setInterval(function () {
          
          

          if (soundMeter != null) {
  
              
  
              // if(element!=null)
              {
  
                // var sound_l = soundMeter.instant.toFixed(2) ;
                var sound_l = soundMeter.db.toFixed(2);
                _this.sound_l2 = soundMeter.db2.toFixed(2);
                var max_freq_index = soundMeter.max_freq_index
  
                if(max_freq_index==undefined || max_freq_index==null)
                {
                  max_freq_index = 0
                }



              
                
                max_freq_index = max_freq_index.toFixed(2);
  
  
                // let sound_calibration = 20;
                let sound_threshold = 52;
                
  
                console.debug('sound_l = ' + sound_l)
                if(sound_l>sound_threshold)
                {
                
                  _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                  
                  _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

                  _this.max_loudness = Math.max(_this.max_loudness,sound_l)
  
                  console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)
  
  
                  _this.counter_loudness += 1;
  
                  
                
                }
  
                  const min_spect_loudness = 50
  
  
                  if(sound_l>min_spect_loudness )
                  {

                    if(_this.hasSpectrogram )
                    {
                      _this.addDataChartSpect(max_freq_index)

                      console.debug('frequency_data_add = ' + max_freq_index)


                    }
                    

                    _this.frequency_data.push(Number(max_freq_index))

                
  
                  }
  
                if(!(_this.hasSPLMeter||_this.hasSpectrogram))
                {
                  let element = document.getElementById("meter")
  
                  _this.canvasContext = element.getContext("2d");
                  _this.drawLoop(sound_l);
  
                }
  
                if(_this.hasSPLMeter)
                {
  
                // _this.addDataChart(max_freq_index)
                _this.addDataChart(sound_l)
                }
  
              
              
              }
  
          }
  
          }, interval_time);
        
  
        },
        roundNumber(number,number_of_decimals)
        {   
          let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)
  
          return rounded_number;
  
        },
        showimage(img_address) {
  
  
          const _this = this
          var storageRef = _this.$firebase.storage().ref();
          storageRef.child(img_address).getDownloadURL().then(function(url) {
              _this.url2image = url
              // var test = url;
              // document.getElementById('img_naming').src = test;
  
          }).catch(function(error) {
  
          });
        },
        skipExercise()
        {
          const _this = this
  
          _this.$emit('nextExercise');
  
        },
        noAudioCaptureNext()
        {
          const _this = this;
  
          _this.is_recording = false
  
          let avg_loudness_array = []
  
          var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
  
  
          _this.$emit('logExerciseResults', basicData);
  
          
          _this.$emit('nextExercise');
  
  
        },  
        goToResults()
        {
          const _this = this
  
          if(_this.play_status)
          {
            _this.last_loudness = this.roundNumber(_this.avg_loudness,2)
  
            let avg_loudness_array = []
  
            if(_this.avg_loudness>0)
            {
              avg_loudness_array.push(_this.avg_loudness)
            }
  
            _this.avg_loudness = 0;
            _this.total_loudness = 0;
            _this.counter_loudness = 0;
  
            if(_this.is_recording) {
              _this.is_recording = false
              _this.endRecordingAudio(true)  
            }
            _this.max_loudness = 0

            var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}

            _this.$emit('logExerciseResults', basicData);
          }
  
          _this.$emit('goToResults', true);
  
  
        },
        playPause()
        {
            
          const _this = this;

          if(_this.play_status)
          {

  
            _this.last_loudness = this.roundNumber(_this.avg_loudness,2)
  
            let avg_loudness_array = []
  
            if(_this.avg_loudness>0)
            {
              avg_loudness_array.push(_this.avg_loudness)
            }

  
            _this.avg_loudness = 0;
            _this.total_loudness = 0;
            _this.counter_loudness = 0;
  
            if(_this.is_recording)
            {
              _this.is_recording = false
  
              _this.endRecordingAudio(true)
  
            }
            _this.max_loudness = 0


            if(_this.resultsTableBack.length>0)
            {
              var basicData = {resultsTableBack: [_this.resultsTableBack[this.resultsTableBack.length-1]], avg_loudness_array: avg_loudness_array}
              _this.$emit('logExerciseResults', basicData);
            } else {
              var basicData = {resultsTableBack: null, avg_loudness_array: avg_loudness_array}
              _this.$emit('logExerciseResults', basicData);
            }

  
  
  
  


            // if(this.userType!='slp')
            // {

            //   this.nextExercise()

            // }
            // else
            // {

              this.show_results = true

              // _this.hasTimer = false
              _this.phonationOn = false


              let rand_pic_id = Math.floor(Math.random() * constants.num_meme_imgs)
              _this.memeImg = new URL(`../../../../../assets/images/well_done/well_done${rand_pic_id}.jpeg`, import.meta.url)

              // let db_pop = new Modal(document.getElementById('db_pop'));
              // db_pop.show()

              this.play_status = false

              if(this.audio_player!=null)
              {
                this.audio_player_status = false
                this.audio_player.pause();
                
              }

            // }
  
            // _this.hasTimer = false;
            
          }
          else
          {
              _this.play_status = true;
              _this.play_pause_text = 'Stop'
              _this.phonationOn = true

  
              _this.resumeRecording()
  
              // if(_this.hasSPLMeter)
              {
                _this.runTimer()
              }
  
          }
  
        },
        nextExercise()
        {
          this.$emit('nextExercise');

        },
        resumeRecording()
        {
            var _this = this;
        
            // resumeRecordingMedia();
            if(!_this.is_recording)
            {
                recordAudioMedia()
                _this.is_recording = true;
            }
    
        },
        loadBackgroundAudio()
        {
          let _this = this;

          var storage = _this.$firebase.storage();

          storage.ref(_this.background_audio_location).getDownloadURL()
          .then((url) => {
            console.debug('loaded background audio')
            _this.background_audio_url = url
            _this.playBackgroundAudio()
            // _this.play(url);

          })
          .catch((error) => {
          });
        
        },
        stopBackgroundNoise()
        {



          if(this.audio_player.paused)
          {
            this.background_noise_checked = true
            var checkbox = document.getElementById("flexCheckChecked");

            checkbox.checked = this.background_noise_checked

            sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)

            this.playBackgroundAudio()

          }
          else
          {
            this.audio_player_status = false
            this.audio_player.pause();

            this.background_noise_checked = false

            var checkbox = document.getElementById("flexCheckChecked");

            checkbox.checked = this.background_noise_checked

            sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)



            this.audio_player_text = 'Resume background noise'

          }

        },
        playBackgroundAudio() {
          const _this = this


          if(_this.audio_player == null)
          {
            _this.audio_player = new Audio(this.background_audio_url);


            var checkbox = document.getElementById("flexCheckChecked");

            checkbox.checked = _this.background_noise_checked


            sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)


            if(_this.background_noise_checked)
            {


              _this.audio_player.play();

              this.audio_player_text = 'Stop background noise'
              this.audio_player_status = true


            }


          } else if(_this.background_noise_checked)
          {

            if(!_this.audio_player.paused) {
              _this.audio_player.pause();
            }
            _this.audio_player = new Audio(this.background_audio_url);
            this.audio_player_status = true
            this.audio_player_text = 'Stop background noise'

            var checkbox = document.getElementById("flexCheckChecked");
            _this.background_noise_checked = true
            checkbox.checked = _this.background_noise_checked

            sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)


            _this.audio_player.play();
            
          }
        },



        pauseRecordingAudio()
        {
  
            const _this = this;
  
            if(_this.is_recording)
            {
                stopRecordingMedia(null,null,null);
                _this.is_recording = false;
  
            }
        
  
        },
        endRecordingAudio(save_audio)
        {

        
            const _this = this;

  
    
            if(save_audio)
            {
  
              console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)
  
              let results_addresses = generate_results_path(this.exForm)

              let random_identifier = results_addresses['random_identifier']

              let exercises_results_path = results_addresses['exercises_results_path']
              let modules_results_path = results_addresses['modules_results_path']

              console.log(_this.exForm.curr_exercise_path)
              let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

              if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
                audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
              }

              var audio_address = 'audios/' + audio_folder + '.mp3';

              audio_address = audio_address.replaceAll('//','/')

              console.debug("Saving " + audio_address);

              
              let today = _this.exForm.today
              
              let voicetype = _this.exForm.voicetype
              let speechtype = _this.exForm.speechtype
              
              _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

              console.debug('exercises_results_path = ' + exercises_results_path)
              console.debug('assessment_type = ' + _this.exForm.assessment_type)
              
              updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

              let name = _this.exForm.name
              if(name == undefined || name == null || name == '')
              {
                name = _this.Ex_Text
              }
              
              let push_structure = {
                'date': today, 
                'name': name, 
                'path': exercises_results_path, 
                'audio_address': audio_address, 
                'uid': _this.uid, 
                'loudness': _this.last_loudness,
                'localisation': _this.region
              }

              let ground_truth = _this.Ex_Text

              console.log('ground_truth = ' + ground_truth)

              if(ground_truth!=undefined && ground_truth!=null)
              {
                ground_truth = removeQuotesAndSimilarForAnalysis(ground_truth)
              } else{
                ground_truth = ''
              }

              console.log('ground_truth2 = ' + ground_truth)

              let has_analysis = false

              if(voicetype!=null && voicetype!=undefined)
              {
                  has_analysis = true               

                if(Array.isArray(voicetype) && voicetype.includes('intelligibility'))
                {
                push_structure['intelligibility'] = ground_truth
                }
                else if('Intelligibility' in voicetype)
                {
                push_structure['intelligibility'] = ground_truth

                }

                if(Array.isArray(voicetype) && voicetype.includes('sentencegibility'))
                {

                push_structure['sentencegibility'] = ground_truth

                }
                else if('sentencegibility' in voicetype)
                {
                push_structure['sentencegibility'] = ground_truth
                }


                if(Array.isArray(voicetype) && voicetype.includes('max_phonation'))
                {
                push_structure['max_phonation'] = '1'
                }
                else if('max_phonation' in voicetype)
                {
                push_structure['max_phonation'] = '1'
                }

                console.log('check pitch_range')

                if(Array.isArray(voicetype) && voicetype.includes('speech_rate'))
                {
                  console.debug('speech_rate')
                push_structure['speech_rate'] = '1'
                }
                else if('speech_rate' in voicetype)
                {
                push_structure['speech_rate'] = '1'
                }

                if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer'))
                {
                push_structure['jitter_shimmer'] = '1'
                }
                else if('jitter_shimmer' in voicetype)
                {
                push_structure['jitter_shimmer'] = '1'
                }


                if(Array.isArray(voicetype) && voicetype.includes('f0'))
                {
                push_structure['f0'] = '1'
                }
                else if('f0' in voicetype)
                {
                push_structure['f0'] = '1'
                }
                      
              }


              if(speechtype!=null && speechtype!=undefined)
              {
                has_analysis = true


                if(Array.isArray(speechtype) && speechtype.includes('ddk_rate'))
                {
                push_structure['ddk_rate'] = '1'
                }

                if(Array.isArray(speechtype) && speechtype.includes('intelligibility'))
                {
                push_structure['intelligibility'] = ground_truth
                }
                else if('Intelligibility' in speechtype)
                {
                push_structure['intelligibility'] = ground_truth
                }


                if(Array.isArray(speechtype) && speechtype.includes('sentencegibility'))
                {
                push_structure['sentencegibility'] = ground_truth

                }
                else if('sentencegibility' in speechtype)
                {
                push_structure['sentencegibility'] = ground_truth


                }

                if(Array.isArray(speechtype) && speechtype.includes('speech_rate'))
                {
                push_structure['speech_rate'] = '1'
                }
                else if('speech_rate' in speechtype)
                {
                push_structure['speech_rate'] = '1'
                }

              }


              if(Array.isArray(voicetype) && voicetype.includes('pitch_range'))
              {

                if(this.frequency_data.length>0)
                {
                  
                  let max_freq = 0
                  let min_freq = 1e6
                  let mean_freq = 0


                  for(let i in this.frequency_data)
                  {
                    let curr_freq = this.frequency_data[i]
                    mean_freq = mean_freq+curr_freq
                    if(curr_freq>max_freq)
                    {
                      max_freq = curr_freq
                    }                   
                    
                    if(min_freq>curr_freq)
                    {
                      min_freq = curr_freq
                    }
                  }

                  mean_freq = mean_freq/this.frequency_data.length

                  min_freq = mean_freq+mean_freq-max_freq

                  let freq_range = max_freq-min_freq

                  this.freq_range = this.roundNumber(freq_range,0)
                  this.max_freq = this.roundNumber(max_freq,0)
                  this.min_freq = this.roundNumber(min_freq,0)

                  if(isNaN(this.freq_range))
                  {
                    this.freq_range = 0
                  }

                  if(isNaN(this.max_freq))
                  {
                    this.max_freq = 0
                  }
                  if(isNaN(this.min_freq))
                  {
                    this.min_freq = 0
                  }

                  let freq_results = {freq_range:freq_range,mean_freq:mean_freq,max_freq:max_freq,min_freq:min_freq,loudness:_this.last_loudness}

                  let resultsTablePre = {exercise_type: 'Pitch Range', exercise: _this.exForm.name, results: freq_results, audio_address:audio_address}

                  var pitch_range_data = {resultsTablePre: resultsTablePre}

                  _this.exForm['random_identifier'] = random_identifier




                  save_pre_computed_results(_this.exForm, pitch_range_data)

                  this.$emit('logExerciseResults', pitch_range_data);

                }
                  
              }


              console.log(push_structure)


              if(has_analysis)
              {
                _this.resultsTableBack.push(exercises_results_path)
                _this.modules_results_path = modules_results_path
                // console.debug('path = ' + modules_results_path)
                // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
                stopRecordingMedia(audio_address, push_structure, _this);

            
              }
              else if('capture_audio' in _this.exForm && _this.exForm.capture_audio)
              {
                push_structure['real_life_exercise'] = this.Ex_Text

                _this.resultsTableBack.push(exercises_results_path)
                _this.modules_results_path = modules_results_path
                // console.debug('path = ' + modules_results_path)
                // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
                stopRecordingMedia(audio_address, push_structure, _this);
              }
              else
              {
                stopRecordingMedia(audio_address, null, null);
              }
            } else {
              stopRecordingMedia(null,null,null);
            }            

  
            this.is_recording = false;
  
        }, 
      createChart()
      {
        const _this = this
        
      //   return
        if(_this.chart_not_created)
        {
          _this.chart_not_created = false
          console.debug('creating chart')
          console.debug('this.loud_target = ' + this.loud_target)
          _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}],maxValue:_this.maxdBValue,minValue:_this.mindBValue})
          var canvas = document.getElementById("loud_canvas")
          _this.chart_data = new smoothie.TimeSeries();
          _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
          // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
          _this.smoothie.streamTo(canvas, 500);
  
        }
      },
      updateChart()
      {
        const _this = this

        localStorage.setItem('loudness_target',_this.loud_target)

        let uid = localStorage.getItem('uid');
        let ref_path = 'Users' + uid + '/Settings/'

        updateDataDirectory(ref_path, {'loudness_target':String(_this.loud_target)})        

        _this.smoothie.stop()

        _this.smoothie.options.horizontalLines = [{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}]
        
        _this.smoothie.start()
        // var canvas = document.getElementById("loud_canvas")
        // _this.chart_data = new smoothie.TimeSeries();
        // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
        // // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
        // _this.smoothie.streamTo(canvas, 500);

      },
      createChartSpect()
      {
        const _this = this
        
        if(_this.chart_not_created_spect)
        {
          _this.chart_not_created_spect = false
          console.debug('creating spec chart')
  
  
          _this.smoothie_spect = new smoothie.SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000',  precision:0},grid:{fillStyle:'rgba(40,40,154,0.60)',strokeStyle:'#ffffff', verticalSections:4},maxValue:500,minValue:50})
          var canvas = document.getElementById("spect_canvas")
          _this.chart_data_spect = new smoothie.TimeSeries();
  
          _this.smoothie_spect.addTimeSeries(this.chart_data_spect, {lineWidth:3.3,strokeStyle:'#00ff00'});
          _this.smoothie_spect.streamTo(canvas, 500);
  
        }
  
      },
      addDataChart(in_data)
      {
        const _this = this
        if(_this.smoothie!=undefined && _this.smoothie!= null)
        {
          _this.chart_data.append(new Date().getTime(),in_data)
        }
  
      },
      addDataChartSpect(in_data)
      {
        const _this = this
        if(_this.smoothie_spect!=undefined && _this.smoothie_spect!= null)
        {
          _this.chart_data_spect.append(new Date().getTime(),in_data)
        }
      },
      pause()
      {
          const _this = this;
          _this.play_status = false;
          _this.play_pause_text = _this.start_btn_text
  
          clearInterval(window.refreshIntervalId)
  
      },
      drawLoop(sound_l) {
        // clear the background
  
        var _this = this;
  
  
        var WIDTH = 400;//document.getElementById('meter').clientWidth;
        var HEIGHT = 30;//document.getElementById('meter').clientHeight;;
  
        var green_threshold = 50;
  
        // console.debug("Width = " + WIDTH);
        
        // console.debug("HEIGHT = " + HEIGHT);
  
        _this.sound_l = sound_l;
  
  
  
        if(_this.canvasContext != undefined && _this.canvasContext !=null)
        {
          _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
        
          if (sound_l > green_threshold) {
            _this.canvasContext.fillStyle = "green";
  
  
          }
          else {
  
            // _this.silence_flag = true;
  
            _this.canvasContext.fillStyle = "red";
          }
          _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
                  // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);
  
  
        }
  
      // draw a bar based on the current volume
  
  
      },
  
  
      
    
      playAudioInstructions(url)
      {
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="400px" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();
      },
  
     
      }
  }
  </script>
  
  <style scoped>

.parent {
    position: relative;
    top: 0;
    left: 0;
  }
  .backimg {
    position: relative;
    top: 0;
    left: 0;
    /* border: 1px red solid; */
  }
  .frontimg {
    position: absolute;
    /* top: 60px;
    left: 500px; */
    /* border: 1px green solid; */
  }

    .text {
      font-size: 14px;
    }
  
    .text_item {
      /* margin-bottom: 18px; */
      font-size: 30px;
      /* vertical-align: middle; */
      padding: 40px 0;
      /* height:80px; */
    }
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both
    }
  
    .box-card {
      width: 60%;
      margin:auto;
    }
  
    .demo-input-label {
      display: inline-block;
      width: 130px;
    }

    .legend-custom-style {
      font-size: 20px;
    }
</style>

<!-- Result Description
{
  "<plan_name?>":{
    "<key>": {
      "audio_address": "<storage_audio_address>",
      "loudness": {
        "loudness": "<int>"
      },
      "name": "<exercise_name>", // Back end analysis results (if they are requested):
      "sentencegibility": "<sentencegibility_dict_see_below>", // daily news, pub quiz, jokes, tongue twisters, fun facts, sentence intelligibility and speech rate (Assessments)
      "intelligibility": "<intelligibility_dict_see_below>", //practice mouth movement
      "max_phonation_dictionary":"<max_phonation_dict_see_below>", //say ahhh
      "jitter_shimmer_dictionary":"<jitter_shimmer_dict_see_below>", //say ahhh
      "speechrate_dictionary":"<speech_rate_dict_see_below>", //sentence intelligibility and speech rate (Assessments)
      "f0_dictionary":"<f0_dict_see_below>", // pitch variation
      "resultsTablePre":"<pre_computed_results>"// pitch variation

       

    },
    "assessment_type": "<USER_TRAINING/SLP_TRAINING/SLP_ASSESSMENT>",
    "plan_name": "<plan_name>"
  }
}

{
  "resultsTablePre":
    {
    "audio_address": "<audio_address>",
    "exercise": "<ex_name>",
    "exercise_type": "Pitch Range",
    "results": {
      "freq_range": <float>,
      "max_freq": <float>,
      "mean_freq": <float>,
      "min_freq": <float>
    }
  }
}
{
  "<sentencegibility_dict>":
  {
    "accuracy_score": "<int>",
    "audio_duration": "<float in sec>",
    "fluency_score": "<int>",
    "reference_text": "<target_text>",

    "Words":[
      // For each word recognised
      {
        "word":"<ground truth word>",
        "accuracy_score": "<int>",
        "error_type": "<string>",
        "phonemes": [
          // For each phoneme in the word
          {
            "accuracy_score":"<int>",
            "phoneme":"<using iso text>"
          }
        ]
      }

    ]

  }
}

{
  "jitter_shimmer_dictionary":
  {
    "apq11Shimmer": "<float>",
    "apq3Shimmer": "<float>",
    "aqpq5Shimmer": "<float>",
    "audio_duration": "<float>",
    "ddaShimmer": "<float>",
    "ddpJitter": "<float>",
    "hnr": "<float>",
    "localJitter": "<float>",
    "localShimmer": "<float>",
    "localabsoluteJitter": "<float>",
    "localdbShimmer": "<float>",
    "meanF0": "<float>",
    "ppq5Jitter": "<float>",
    "rapJitter": "<float>",
    "stdevF0": "<float>"
  }
}
{
  "max_phonation_dictionary":
  {
    "audio_duration": "<float>",
    "max_phonation": "<float>"
  }
}

{
  "f0_dictionary":{
    "apq11Shimmer": "<float>",
    "apq3Shimmer": "<float>",
    "aqpq5Shimmer": "<float>",
    "audio_duration": "<float>",
    "ddaShimmer": "<float>",
    "ddpJitter": "<float>",
    "hnr": "<float>",
    "localJitter": "<float>",
    "localShimmer": "<float>",
    "localabsoluteJitter": "<float>",
    "localdbShimmer": "<float>",
    "meanF0": "<float>",
    "ppq5Jitter": "<float>",
    "rapJitter": "<float>",
    "stdevF0": "<float>"
  
  }
}
{
  "intelligibility":{
    "Error": "<string>",
    "Phonemes": [
      // For each phoneme in word
      {
        "Duration": "<int in microseconds>",
        "Offset": "<int in microseconds>",
        "Phoneme": "<string in iso>",
        "PronunciationAssessment": {
          "AccuracyScore": "<int>"
        }
      }
    ],
    "accuracy_score": "<int>",
    "audio_duration": "<float>",
    "completeness_score": "<int>",
    "fluency_score": "<int>",
    "pronunciation_score": "<float>",
    "reference_text": "<target_text"
  }
}


{
  "speechrate_dictionary":{
    "ASD(speakingtime by nsyll)": "<float>",
    "articulation rate(nsyll by phonationtime)": "<float>",
    "audio_duration": "<float>",
    "dur(s)": "<float>",
    "npause": "<int>",
    "nsyll": "<int>",
    "phonationtime(s)": "<float>",
    "speechrate(nsyll by dur)": "<float>"
  }
}
 -->