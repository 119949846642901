<template>
  <div>       

    <div class="row">
      <video ref="liveVideoElement" muted center id="live" autoplay playsinline></video> 
    </div>
    <div class="row justify-content-center">
      <button v-if="!recording && !saving" type="button" class="btn btn-danger mt-2 w-75" @click="record()">
        <i class="bi bi-record"></i> Record 
      </button>
      <button v-else-if="recording && !saving" type="button" class="btn btn-primary mt-2 w-75" @click="stop()">
        <i class="bi bi-device-hdd"></i> Save 
      </button>
      <button v-else type="button" disabled class="btn btn-primary mt-2 w-75">
        <i class="bi bi-clock-history"></i> Saving...
      </button>
    </div>

  </div>
</template>
<script>

import {BV_VideoRecorder} from '@/media_input/video_recording'
import { useToast } from "vue-toastification";
import { ref, onMounted, defineComponent, onUnmounted } from 'vue';

export default defineComponent({
  name: 'record_instructions',
  props: {
    video_type: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {emit}) {
    const uid = localStorage.getItem('uid')
    const recording = ref(false)
    const saving = ref(false)
    const liveVideoElement = ref(null)
    const bv_VideoRecorder = ref(null)


    let type_dir = ''

    if(props.video_type) {
      type_dir = 'video_instructions/'
    } else {
      type_dir = 'audio_instructions/'
    }

    const random_identifier = Math.random().toString(16).slice(2) + '.mp4'
    const record_address = 'user_instructions/' + type_dir + uid  + '/' + random_identifier

    onMounted(() => {      
      bv_VideoRecorder.value = new BV_VideoRecorder(liveVideoElement.value, true);

      if (liveVideoElement.value) {
        //bv_VideoRecorder.value.checkPermissionsMedia();
      } else {
        console.error('ERROR: null video element');
      }
    })
    
    const record = () => {
      recording.value = true
      saving.value = false

      bv_VideoRecorder.value.record(record_address)
    }

    const stop = async () => {
      recording.value = false
      saving.value = true

      bv_VideoRecorder.value.stop()
      
      bv_VideoRecorder.value.recordingPromise.then(() => {
        const toast = useToast()
        toast.info("Recording added successfully.")
        if(props.video_type) {
          emit('videoClosed', record_address);
        } else {
          emit('audioClosed', record_address);
        }
        recording.value = false
        saving.value = false
      })
      .catch((error) => {
        console.error('Error during recording:', error);
      });
      
    }


    onUnmounted(() => {
      console.log("destroying")
      BV_VideoRecorder.value.destroy()
      console.log("destroyed")
    })

    return {
      liveVideoElement,
      recording,
      saving,

      record,
      stop,
      
    }
  }
})
</script>
<style scoped>

</style>