import { updateDataDirectory } from "@/firebase"
import {getTodayString} from '@/utils/utils'


export function generate_interactive_results_path(date, module_name, exercise_index) {

  let session_type = 'remote_assessment'
  
  let package_name = 'general'

  let uid = localStorage.getItem('uid');

  let path_to_module_from_uid = '/Results/' + session_type + '/'+ package_name + '/' + date + '/' + module_name

  let exercises_results_path =  '/Users/'+ uid + path_to_module_from_uid + '/exercises/' + exercise_index;
  let modules_results_path = '/Users/'+ uid + path_to_module_from_uid

  exercises_results_path = exercises_results_path.replaceAll('//','/')
  modules_results_path = modules_results_path.replaceAll('//','/')
  
  let output = {
    'exercises_results_path': exercises_results_path, 
    'modules_results_path': modules_results_path
  }

  return output
}


export function generate_results_path(exForm)
{
  let userCategory = localStorage.getItem('userCategory');

  // This doesnt work right now, but it would be good if the random identifier was the same 

  let random_identifier = '_' + Math.random().toString(16).slice(2)
  
  if('random_identifier' in exForm) {
    random_identifier = exForm['random_identifier']
  }

  if(exForm.curr_exercise_path && exForm.curr_exercise_path.length>0 && exForm.curr_exercise_path[exForm.curr_exercise_path.length-1] != '/') {
    exForm.curr_exercise_path = exForm.curr_exercise_path + '/'
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //session type 
  //////////////

  let is_assignment = false
  let session_type = 'training'

  if(exForm.assessment_type.includes('ASSIGNMENT')) {
    is_assignment = true
  }

  if(exForm.assessment_type.includes('ASSESSMENT')) {
    if(is_assignment) {
      session_type = 'assignment_assessment' //this only works for patients
    } else {

      if(exForm.assessment_type.includes('REMOTE')) {
        session_type = 'remote_assessment'
      } else {
        if (userCategory == 'slp') {
          session_type = 'clinic_assessment' //this is for slt clients and patient (self-check)
        } else {
          session_type = 'assessment'
        }
      }
    }
  }

  if(exForm.assessment_type.includes('TRAINING')) {

    if(is_assignment) {
      session_type = 'assignment_training' //this only works for patients
    } else {
      if (userCategory == 'slp') {
        session_type = 'clinic_training' //this is for slt clients and patients (daily training)

      } else {
        session_type = 'training'
      }

    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  
  let package_name = 'general' //non packages are named general

  if(exForm['package_uid']) //check if 'package' is used
  {
    if (exForm['package_uid'] !== 'general') {
      package_name = 'packages/' + exForm['package_uid'] + '/exercises/'
    } 
  }

  
  let uid = localStorage.getItem('uid');


  let patient_uid = uid

  if(userCategory == 'slp' && (localStorage.getItem('view_mode')!=undefined && localStorage.getItem('view_mode') != 'demo')) {
    let client_uid = localStorage.getItem('client_uid')

    if (client_uid == null && client_uid === '' ) {
      alert("Error: client number")
    }

    patient_uid = client_uid
  }
  

  let today = getTodayString()

  if(exForm['today']){
    today = exForm.today
  } 

  console.debug('exForm.module_name =  ' +  exForm.module_name)

  if(exForm.module_name=='')
  {

    exForm.module_name = 'temp_module_name'
    
  }

  let exercises_results_path = '/Users/'+ patient_uid + '/Results/' + session_type + '/'+ package_name + '/' + today + '/' + exForm.module_name + '/exercises/' + random_identifier
  let modules_results_path = '/Users/'+ patient_uid + '/Results/' + session_type + '/'+ package_name +  '/' + today + '/' + exForm.module_name


  console.debug('exercises_results_path = ' + exercises_results_path)
  exercises_results_path = exercises_results_path.replaceAll('//','/')
  modules_results_path = modules_results_path.replaceAll('//','/')
  
  let output = {
    'modules_results_path': modules_results_path, 
    'exercises_results_path': exercises_results_path, 
    'random_identifier': random_identifier
  }

  return output
}


export function save_pre_computed_results(exForm, results_object)
{

  let results_type = 'pre_computed'

  if(!('audio_address' in results_object)) {
    if(!('resultsTablePre' in  results_object))
    {
      results_object['audio_address'] = ''
    }
    else if(!('audio_address' in results_object['resultsTablePre']))
    {
      results_object['resultsTablePre']['audio_address'] = ''
    }
  }

  let results_addresses = generate_results_path(exForm)

  let exercises_results_path = results_addresses['exercises_results_path']
  let modules_results_path = results_addresses['modules_results_path']


  updateDataDirectory(modules_results_path, { plan_name: exForm.module_name, assessment_type: exForm.assessment_type} )

  updateDataDirectory(exercises_results_path, results_object)
  updateDataDirectory(exercises_results_path, {results_type:results_type})
}
