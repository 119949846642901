<template>
  
  <div v-if="results['EMST']" >
    <emst 
    name="EMST"
    :session_results="results['EMST'][0]"
    :today_results="results['TODAY_EMST']" 
    />
  </div>

  <div v-if="results['Dysphagia']" >
    <dysphagia 
    name="Swallowing exercises"
    :results="results['Dysphagia']" 
    />
  </div>
  
</template>
<script>

import emst from '@/views/session/Result/type/emst.vue'

import dysphagia from '@/views//session/Result/type/dysphagia.vue'

export default {
  name: 'dysphagia_section',
  components: {
    emst,
    dysphagia
  },
  props: ['results'],
  setup(props) {
    return {

    }
  }
}
</script>
<style>

  </style>