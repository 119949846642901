<template>
  <div class="row mt-2">
    

    <div class="card">


      
    

      <div v-if="'FDA' in q_data">

          <div class="col">
            <h4>FDA</h4>
          </div>

          <Single_fda
              :curr_fda="q_data['FDA']">
          </Single_fda>

          
          

            
      </div>

      <div v-if="'Swal-Qol' in q_data">
        <div class="row">
          <div class="col">
              <div class="row mt-2" style="text-align: left;">
              <div class="col">
                <h4>Swal-Qol Total:</h4>
              </div>
              <div class="col">
                <h4>{{q_data['Swal-Qol']['Score']['Overall'].toFixed(0)}}</h4>

              </div>
            </div>
            
            <div  class="row" style="text-align: left;">
              <p class="lead">Swal-Qol score interpretation:</p>

            </div>

            <div  class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Swal-Qol score</th>
                  <th scope="col">Level of impact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>80-100</td>
                  <td>Low-Mild Impact</td>
                </tr>
                <tr>
                  <td>50-80</td>
                  <td>Moderate Impact</td>
                </tr>
                <tr>
                  <td>0-50</td>
                  <td>Severe Impact</td>
                </tr>
               
              </tbody>
            </table>
          </div>  


          <div  class="row" style="text-align: left;">
              <p class="lead">Swal-Qol by domain:</p>

            </div>

            <div  class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Domain</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row, index in q_data['Swal-Qol']['Score']['Domains']" v-bind:key="index" >
                  <td>{{row['Name']}}</td>
                  <td>{{row['score'].toFixed(0)}}/10</td>
                </tr>
               
               
              </tbody>
            </table>
          </div>
          
          <div class="mt-4">
            <p class="h4 text-start">Individual questionnaire responses</p>
          </div>


          <div v-for="row, index in q_data['Swal-Qol']['Data']" v-bind:key="index">
    
            <div v-if="row['question_type']=='A'">

              {{ row['instruct']}}
              <table class="table" style="table-layout: fixed">
              <thead>
                  <tr>
                    <th></th>
                    <th v-for="option, index2 in row['radio_options']" v-bind:key="index2">{{ option['label'] }}</th>
              

                  </tr>
                </thead>

            
              <tbody>
                  <tr v-for="question, index3 in row['qs']" v-bind:key="index3">

                  <td>{{ question }}</td>

                  <th v-for="option, index2 in row['radio_options']" v-bind:key="index2">
                    <h4 v-if="(index2+1)==row['selections'][index3]"> X</h4>
                    </th>



                  </tr>
                

                </tbody>
              </table>
              <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

            </div>
            <div v-if="row['question_type']=='B' && row['selections'].indexOf(true)>=0">

             {{ row['instruct']}}  


              <h6> {{ row['qs'][row['selections'].indexOf(true)]['value'] }} - {{ row['qs'][row['selections'].indexOf(true)]['label'] }}</h6>

              <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

            </div>


          </div>




              <!-- <div v-for="row, index in q_data['questions']" v-bind:key="index" class="row mt-2" style="text-align: left;">
                <div class="col">
                  <h4>Emotional</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['E']['total_score'].toFixed(0)}}</h4>
                </div>
              </div>
              <div class="row" style="text-align: left;">
                <div class="col">
                  <h4>Physical</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['P']['total_score'].toFixed(0)}} </h4>
                </div>
              </div>
              <div class="row border-bottom" style="text-align: left;">
                <div class="col">
                  <h4>Functional</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['F']['total_score'].toFixed(0)}}</h4>
                </div> -->
              <!-- </div> -->

          </div>
        </div>

      </div>


      <div v-if="'la_trobe_self' in q_data || 'la_trobe_relative' in q_data ">


        <div v-if="'la_trobe_self' in q_data" class="col">
          <h4>La Trobe Communication Questionnaire (self)</h4>
        </div>
        <div v-if="'la_trobe_relative' in q_data" class="col">
          <h4>La Trobe Communication Questionnaire (relative)</h4>
        </div>


        <Single_la_trobe
              :q_data="q_data">
          </Single_la_trobe>

      </div>

      


      <div v-if="'VHI' in q_data">

        <div class="row">
          <div class="col">
            <div v-if="'vhi_categories' in q_data['VHI']">
              <div class="row border-bottom" style="text-align: left;">
                <h4>Subscale by category</h4>
              </div>
              <div class="row mt-2" style="text-align: left;">
                <div class="col">
                  <h4>Emotional</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['E']['total_score'].toFixed(0)}}</h4>
                </div>
              </div>
              <div class="row" style="text-align: left;">
                <div class="col">
                  <h4>Physical</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['P']['total_score'].toFixed(0)}} </h4>
                </div>
              </div>
              <div class="row border-bottom" style="text-align: left;">
                <div class="col">
                  <h4>Functional</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['F']['total_score'].toFixed(0)}}</h4>
                </div>
              </div>
            </div>


            <div v-if="'total_score' in q_data['VHI'] && q_data['VHI']['total_questions']==30" class="row mt-2" style="text-align: left;">
              <div class="col">
                <h4>Total</h4>
              </div>
              <div class="col">
                <h4>{{q_data['VHI']['total_score'].toFixed(0)}}</h4>
              </div>
            </div>

            <div v-if="'total_score' in q_data['VHI'] && q_data['VHI']['total_questions']==10" class="row mt-2" style="text-align: left;">
              <div class="col">
                <h4>VHI-10 Total</h4>
              </div>
              <div class="col">
                <h4>{{q_data['VHI']['total_score'].toFixed(0)}}</h4>
              </div>
            </div>
            <div v-if="'total_score' in q_data['VHI'] && q_data['VHI']['total_questions']==10" class="row" style="text-align: left;">
              <!-- <p class="lead">VHI-10 score interpretation: {{ q_data['severity_level'] }}</p> -->
              <p class="lead">VHI-10 score interpretation: {{ q_data['VHI']['total_score_meaning'] }}</p>
              <!-- <p class="text-start"> {{ q_data['total_score_meaning'] }}</p> -->

            </div>
              
          </div>
          
          <div v-if="q_data['VHI']['total_questions']==30" class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">VHI-30 score</th>
                  <th scope="col">Level of impact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>0-14</td>
                  <td>Healthy</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>15-28</td>
                  <td>Mild</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>29-50</td>
                  <td>Moderate</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>51-120</td>
                  <td>Severe</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="q_data['VHI']['total_questions']==10" class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">VHI-10 score</th>
                  <th scope="col">Level of impact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>0-3</td>
                  <td>Healthy</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>3-11</td>
                  <td>Mild </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>12-40</td>
                  <td>Moderate to severe</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
          <div v-if="'z_score' in q_data['VHI']" class="row" style="text-align: left;margin-top: 10mm;">
            <div class="col-md-3">
              <h4>Z score</h4>
            </div>
            <div class="col-md-2">
              <h4>{{q_data['VHI']['z_score'].toFixed(0)}}</h4>
            </div>
          </div>
          <div v-if="'z_score' in q_data['VHI']" class="row" style="text-align: left;">
            <p class="lead">Z score interpretation: {{q_data['VHI']['z_score_meaning']}}</p>
          </div>
        </div>
        <div v-if="'VHI' in q_data">
          <div class="mt-4">
            <p class="h4 text-start">Individual questionnaire responses</p>
          </div>
          <table class="table mt-4" >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Question</th>
                <th scope="col">Score</th>

                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in q_data['questions']" v-bind:key="index">
                <td>{{ index +1 }}</td>
                <td class="text-start">{{ q_data['questions'][index] }}</td>
                <td v-if="'rating_scores' in q_data">{{ q_data['rating_scores'][index]}}</td>
                <td v-else>NA</td>
                <td>{{ q_data['answers'][index]}}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, onUpdated, computed, onMounted } from "vue";




import Single_la_trobe from "../../../dashboard/slt/client_interface/pages/case_notes/components/la_trobe/single_la_trobe.vue";

import Single_fda from "../../../dashboard/slt/client_interface/pages/case_notes/components/fda/single_fda.vue";
export default {
  name: "questionnaire",
  components:{
    Single_fda,
    Single_la_trobe

    
  },
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
  methods: {
   
  },
  setup(props) {
    

    return {
    };
  },
};
</script>

<style>

</style>
