<template>
  <div >
    <h1 class="display-5">{{title}}</h1>
    <div class="card card-body">
      <div class="m-4">
      <!-- <p class="lead">{{ instruct }}</p> -->
        <FormKit type="form" @submit="handleSubmit" class="questionnaire-form" >

          <div v-for="options,index in all_options" :key="index">

            <div class="form-container">

            <h2 class="form-title"></h2>

            <p class="lead" style="text-align: left; font-size: 1.5em; font-weight: bold; color: rebeccapurple;">{{options['title']}}</p>

            <div class="options-container">
              <div 
                v-for="option in options['options']" 
                :key="option.value" 
                class="radio-wrapper" 
                @click="all_options[index]['selectedOption'] = option.value"
              >
                <label class="radio-label">
                    <FormKit 
                      type="radio"
                      :name="'question_' + index" 

                      v-model="all_options[index]['selectedOption']"

                      :value="option.value"
                      input-class="radio-input"
                      validation="required"
                      :validation-messages="{ required: 'Please select an option before proceeding' }"
                      @input="updateSelection(index, option.value)" 

                    />
                    <span class="radio-text">{{ option.label }}</span>
                  </label>
                  <span class="points-badge">{{ option.points }} points</span>
                </div>
              </div>
              <div style="margin-top: 2%;">

                </div>
                <FormKit 
                  type="textarea"
                  label="Additional Details"
                  name="additionalDetails"
                  v-model="all_options[index]['additionalDetails']"
                  input-class="textarea-input"
                />

          </div>
        </div>

              
            </FormKit>
      </div>

  </div>
</div>

</template>
<script>

import {getUserDataDir, getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {VHI_computeZScore, VHI_computeStandardScore} from '@/utils/session/exercise_type/vhi/vhi'

import {getTodayString} from '@/utils/utils'
import { Modal } from 'bootstrap'

export default {
  name: 'selection_scales',
  props: {
      exForm: {
        type: Object,
      },
    },
  data(){
    return {
      selectedOption: null,
      additionalDetails: '',
      all_options:{},

      assessment_start:'',
      assessment_end:'',
      title:'',
      questionnaire_type:'',
      instruct:'',
      questionnaire_data:{},
      questionnaire_address:'/Exercises/General/Questionnaires/VHI',
      qn_size:10,
      results_dict:null,

      radio_options: [],
      showResults: false,
      form:{
        options:[]
      },
    }
  },
  async created() {
    await this.loadQuestionnaire();


    this.assessment_start = getTodayString()


  },
  methods: {

    updateSelection(index,value)
    {


      console.debug('updateSelection')
      console.debug('value = ' + value)

      let s_index = String(index)

      
      for(let k in this.all_options[s_index]['options'])
      {
        let curr_option = this.all_options[s_index]['options'][k]
        {
          if(curr_option['value']==value)
          {
            this.all_options[s_index]['answer'] = {'value':curr_option['value'],'label':curr_option['label'],'points':curr_option['points']}

          }
        }
        
      }

      this.all_options[s_index]['selectedOption'] = parseInt(value)
      console.debug('value')

      console.debug(value)



    },
    async loadQuestionnaire()
    {
      let _this = this;

      this.questionnaire_address = this.exForm.questionnaire_address

    

      let ref_questionnaires = this.questionnaire_address


      console.debug(ref_questionnaires)

      var do_once = true;
      let count = 0;
      

      return new Promise(resolve => {


      getData(ref_questionnaires , function (err, result) {

          if (do_once) {

            do_once = false;

            let curr_results = result.val()

            _this.questionnaire_data = curr_results

            _this.title = curr_results['title']

            console.debug(curr_results)

            let temp_options = curr_results['all_options']

            _this.all_options = {}

            for(let k in temp_options)
            {
              _this.all_options[k] = temp_options[k]
            }

            for(let k in _this.all_options)
            {
              if('options' in _this.all_options[k])
              {
                _this.all_options[k]['selectedOption'] = null
                
              }
            }



          

            // _this.$forceUpdate()

          

            resolve()
          }


        });
      })
      
    },
    handleSubmit()
    {
        const _this = this

        this.assessment_end = getTodayString()



        // console.debug('form option= '+this.form.options)

        let total_score = 0
        let total_questions = 0
         
        _this.results_dict = {

          questionnaire_address:_this.questionnaire_address


        }



        for(let k in _this.all_options)
        {
          let curr_question = _this.all_options[k]


          console.debug(curr_question)
          let score = curr_question['answer']['points']



          total_score = total_score+score



          total_questions = total_questions+1
          
        }



        this.results_dict['Questionnaire_Name'] = this.questionnaire_data.title


        console.debug('here 4')
        this.results_dict[this.questionnaire_data.questionnaire_type] = {'full_questionnaire':_this.all_options,
          'total_score':total_score,
          'assessment_end':this.assessment_end,
          'assessment_start':this.assessment_start,
        'total_questions':total_questions}

        console.debug('total_score =  ' + total_score)

        console.debug('here 5')


        this.saveResults()



    },
    saveResults()
    {
      const _this = this


      console.debug('saveResults')
      if(_this.results_dict!=null)
      {





        var questionnaire_data = {resultsTablePre: {audio_address:'',exercise_type: 'Selection Scales', exercise: this.questionnaire_data.questionnaire_type, results: _this.results_dict}}



        console.debug(questionnaire_data)
        // _this.exForm.curr_exercise_path = 'VHI'
        
        // _this.exForm.module_name = 'VHI'
        save_pre_computed_results(_this.exForm, questionnaire_data)

        console.debug('saved')
        // _this.bv_points.add(10)

        _this.$emit('logExerciseResults', questionnaire_data);
      }

      _this.$emit('nextExercise');
    },

    
  },
}
</script>
<style scoped>
.form-container {
  max-width: 95%;
  margin: auto;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

:deep(.formkit-message) {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.form-title {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0.1%;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #f9f9f9;
  transition: background 0.3s ease;
}

.radio-wrapper:hover {
  background: #e6f7ff;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;

  gap: 10px; /* Ensures spacing between the radio button and text */
  flex-grow: 1; /* Allows the label text to take available space */
}

:deep(.radio-input) {
  width: 22px;  /* Increase size */
  height: 22px; /* Increase size */
  accent-color: rebeccapurple; /* Change color to purple */
  cursor: pointer; /* Ensure it looks clickable */
}
.radio-text {
  font-size: 1.05em;
}

.points-badge {
  background: #18632a;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: bold;
}

:deep(.textarea-input) {
  margin-top: 15px;
  width: 90%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.submit-button {
  margin-top: 20px;
  background: #007bff;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.submit-button:hover {
  background: #0056b3;
}
</style>