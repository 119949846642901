<template>
  <div>
    <!-- <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div> -->
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='PCA';openModal('speech_visual_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Language_visual_and_audio_processing" class="img-thumbnail text-white rounded-circle" style="background-color: orange;">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Visual / Audio Comprehension</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="speech_visual_modal" tabindex="-1" aria-labelledby="speech_visual_modal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Visual/Audio Comprehension</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('speech_visual_modal');"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PCA'?'btn-primary':'btn-light']" type="button" @click="selected_module='PCA'">
      
                  Components of a word (PCA)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'SpokenPCA'?'btn-primary':'btn-light']" type="button" @click="selected_module='SpokenPCA'">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->
                  Spoken phonological components analysis (PCA)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'SceneClick'?'btn-primary':'btn-light']" type="button" @click="selected_module='SceneClick'">
                  Receptive comprehension with real-life scenarios
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'SceneDescription'?'btn-primary':'btn-light']" type="button" @click="selected_module='SceneDescription'">
                  Q&A using life items
                </button>
                <!--
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PositionExercises'?'btn-primary':'btn-light']" type="button" @click="selected_module='PositionExercises'">
                  Position description
                </button>
                -->
                <button :class="['btn','mb-2', 'w-100', selected_module == 'WriteNumbers'?'btn-primary':'btn-light']" type="button" @click="selected_module='WriteNumbers'">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->
                  Acalculia (hear numbers)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ListenMinimalPairs'?'btn-primary':'btn-light']" type="button" @click="selected_module='ListenMinimalPairs'">
                  <!-- 
                  <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> 
                  -->
                  Audio discrimination of minimal pairs
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'TokenAssessment'?'btn-primary':'btn-light']" type="button" @click="selected_module='TokenAssessment'">
                  Token test
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Visual_Scanning'?'btn-primary':'btn-light']" type="button" @click="selected_module='Visual_Scanning'">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->
                  Visual Scanning
                </button>

              </div>
              <div class="col-8">

                <div v-show="selected_module == 'PCA'">
                  <p class="lead">Improve word-finding through analysing the phonological components of words</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'SpokenPCA'">
                  <p class="lead">Improve word-finding by analysing the phonological components of words (emphasis on speaking)</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>


                <div v-show="selected_module == 'SceneClick'">
                  <p class="lead">Practise audio-visual comprehension with common items</p>
                </div>
                <div v-show="selected_module == 'SceneDescription'">
                  <p class="lead">Practise audio-visual comprehension and word retrieval</p>
                </div>
                <div v-show="selected_module == 'PositionExercises'">
                  <p class="lead">Practise describing the relative positions of items </p>
                </div>

                <div v-show="selected_module == 'WriteNumbers'">
                  <p class="lead">Practise identifying numbers you hear</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'ListenMinimalPairs'">
                  <p class="lead">Practise audio discrimination of semantic meaning by phoneme</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'TokenAssessment'">
                  <p class="lead">Practise following directions of increasing complexity</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="25" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:25" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'Visual_Scanning'">
                  <p class="lead">Practise visual scanning of shapes</p>

                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>
<script>

import Language_visual_and_audio_processing from '@/assets/images/dashboard/slt/training/Language_visual_and_audio_processing.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'visual_audio_processing',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Language_visual_and_audio_processing: Language_visual_and_audio_processing, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "speech_visual_modal": new Modal(document.getElementById('speech_visual_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('speech_visual_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('speech_visual_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    }
  }
}
</script>