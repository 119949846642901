import {getWord_intelligibility_data, getTranscriptionData, getUnscriptedSpeechData, getSentence_intelligibility_data, searchExamplesWithPhoneme} from '@/utils/results/parsers/intelligibility'

import {get_cookie_theft_data} from '@/utils/results/parsers/language_parser'

import {get_real_life_exercise_data, getSpeechrate_data, get_avqi_data,getMax_phonation_data, jitter_shimmer_data, ddk_data, loudnessScore, add_pitch_range_result} from '@/utils/results/parsers/simple'
import {roundNumber} from '@/utils/utils'

import { getUserDataDir, getData, updateData, getDataDirectoryCallback } from '@/firebase/index'

export function parseProccesedAudioExerciseData(exercise_data, results, exercise_path)
{
  let sentence_thresh_poor = 50
  let sentence_thresh_ok = 80
  
  let name = exercise_data['name'];

  console.log('exercise_data = ')
  console.log(exercise_data)
  
  if('ddk_rate_dictionary' in exercise_data)
  {
    if ('error' in exercise_data['ddk_rate_dictionary']) {
      return
    }

    if (!results.hasOwnProperty('ddk_table')) {
      results['ddk_table'] = []
    }
    
    let r = ddk_data(exercise_data, name)
    results['ddk_table'].push(r)
  }

  if('cookie_theft' in exercise_data)
  {
    if ('error' in exercise_data['cookie_theft']) {
      return
    }
    
    console.debug('cookie_theft_data')
    let r = get_cookie_theft_data(exercise_data, name)

    r['cookie_theft']['exercise_path'] = exercise_path
    if (!results.hasOwnProperty('cookie_theft')) {
      results['cookie_theft'] = [r['cookie_theft']]
    } else {
      let exercise_already_exists = false //due to edit
      for ( let i=0; i<results['cookie_theft'].length; i++) {
        console.log(results['cookie_theft'][i])
        if (results['cookie_theft'][i]['exercise_path'] == exercise_path) {
          exercise_already_exists = true
          results['cookie_theft'][i] = r['cookie_theft']
        }
      }
      if (exercise_already_exists == false) {
        results['cookie_theft'].push(r['cookie_theft'])
      }
    }
    console.debug(r['cookie_theft'])

  }

  if('intelligibility' in exercise_data)
  {
    if ('error' in exercise_data['intelligibility']) {
      return
    }
    let r = getWord_intelligibility_data(exercise_data, name)

    if (!results.hasOwnProperty('intelligibility')) {
      results['intelligibility'] = []
    }

    if (!results.hasOwnProperty('suggestionData')) {
      results['suggestionData'] = []
    }

    if (!results.hasOwnProperty('funcFinished')) {
      results['funcFinished'] = []
    }

    if (!results.hasOwnProperty('suggestionList')) {
      results['suggestionList'] = []
    }
    
    //check if it is already in the list
    for(let k in r['suggestionList'])
    {
      if (!results['suggestionList'].includes(r['suggestionList'][k])) {
        results['suggestionList'].push(r['suggestionList'][k])
        results['suggestionData'].push(r['suggestionData'][k])
        results['funcFinished'].push(r['funcFinished'][k])
      }
    }

    let exists = false
    for(let k of results['intelligibility'])
    {
      if (k['Name'].includes(r['intelligibility']['Name'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['intelligibility'].push(r['intelligibility'])
    }

  }

  if('sentencegibility' in exercise_data)
  {

    if ('error' in exercise_data['sentencegibility']) {
      return
    }
    let r = getSentence_intelligibility_data(exercise_data, name, sentence_thresh_poor, sentence_thresh_ok)
    console.log(exercise_data)
    if (!results.hasOwnProperty('avg_sentence_results')) {
      results['avg_sentence_results'] = []
    }

    if (!results.hasOwnProperty('sentencegibility')) {
      results['sentencegibility'] = []
    }

    if (!results.hasOwnProperty('suggestionData')) {
      results['suggestionData'] = []
    }

    if (!results.hasOwnProperty('funcFinished')) {
      results['funcFinished'] = []
    }

    if (!results.hasOwnProperty('suggestionList')) {
      results['suggestionList'] = []
    }
    
    //check if it is already in the list
    for(let k in r['suggestionList'])
    {
      if (!results['suggestionList'].includes(r['suggestionList'][k])) {
        results['suggestionList'].push(r['suggestionList'][k])
        results['suggestionData'].push(r['suggestionData'][k])
        results['funcFinished'].push(r['funcFinished'][k])
      }
    }


    let exists = false
    for(let k of results['sentencegibility'])
    {
      if (k['AudioAddress'].includes(r['sentencegibility']['AudioAddress'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['sentencegibility'].push(r['sentencegibility'])
      results['avg_sentence_results'].push(r['accuracy'])
    }


    const sumWithInitial = results['avg_sentence_results'].reduce(
      (previousValue, currentValue) => previousValue + currentValue, 0
    );
    
    let avg_score = sumWithInitial/results['avg_sentence_results'].length;

    results['avg_sentence_intel'] = 'is very good'
    results['avg_sentence_interp'] = 'People can understand you well.'
    if(avg_score < sentence_thresh_poor)
    {
      results['avg_sentence_intel'] = 'needs improvement'
      results['avg_sentence_interp'] = "It's hard for people to understand what you mean."
    } else if(avg_score < sentence_thresh_ok)
    {
      results['avg_sentence_intel'] = 'is Ok'
      results['avg_sentence_interp'] = 'People can understand you with simple messages or familiar context.'
    }
  }

  if('speechrate_dictionary' in exercise_data)
  {
    if ('error' in exercise_data['speechrate_dictionary']) {
      return
    }

    if (!results.hasOwnProperty('speechTable')) {
      results['speechTable'] = []
    }

    console.log(exercise_data)
    let r = getSpeechrate_data(exercise_data, name)
    console.log(r)
    let exists = false
    for(let k of results['speechTable'])
    {
      if (k['Name'].includes(r['Name'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['speechTable'].push(r)
    }

  }


  if('transcription' in exercise_data)
  {

    if ('error' in exercise_data['transcription']) {
      return
    }


    let r = getTranscriptionData(exercise_data, name, sentence_thresh_poor, sentence_thresh_ok)


    // if (!results.hasOwnProperty('avg_sentence_results')) {
    //   results['avg_sentence_results'] = []
    // }

    if (!results.hasOwnProperty('transcription')) {
      results['transcription'] = []
    }

    if (!results.hasOwnProperty('suggestionData')) {
      results['suggestionData'] = []
    }

    if (!results.hasOwnProperty('funcFinished')) {
      results['funcFinished'] = []
    }

    if (!results.hasOwnProperty('suggestionList')) {
      results['suggestionList'] = []
    }
    
    //check if it is already in the list
    for(let k in r['suggestionList'])
    {
      if (!results['suggestionList'].includes(r['suggestionList'][k])) {
        results['suggestionList'].push(r['suggestionList'][k])
        results['suggestionData'].push(r['suggestionData'][k])
        results['funcFinished'].push(r['funcFinished'][k])
      }
    }


    let exists = false
    for(let k of results['transcription'])
    {

      if (k['Name'].includes(r['transcription']['Name'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['transcription'].push(r['transcription'])
      // results['avg_sentence_results'].push(r['accuracy'])
    }


    // const sumWithInitial = results['avg_sentence_results'].reduce(
    //   (previousValue, currentValue) => previousValue + currentValue, 0
    // );
    
    // let avg_score = sumWithInitial/results['avg_sentence_results'].length;

    // results['avg_sentence_intel'] = 'is very good'
    // results['avg_sentence_interp'] = 'People can understand you well.'
    // if(avg_score < sentence_thresh_poor)
    // {
    //   results['avg_sentence_intel'] = 'needs improvement'
    //   results['avg_sentence_interp'] = "It's hard for people to understand what you mean."
    // } else if(avg_score < sentence_thresh_ok)
    // {
    //   results['avg_sentence_intel'] = 'is Ok'
    //   results['avg_sentence_interp'] = 'People can understand you with simple messages or familiar context.'
    // }
  }

  if('unscripted_speech_recognition' in exercise_data)
  {

    if ('error' in exercise_data['unscripted_speech_recognition']) {
      return
    }

    if(!('transcription' in exercise_data['unscripted_speech_recognition']))
    {
      return 
    }


    let r = getUnscriptedSpeechData(exercise_data, name, sentence_thresh_poor, sentence_thresh_ok)


    // if (!results.hasOwnProperty('avg_sentence_results')) {
    //   results['avg_sentence_results'] = []
    // }

    if (!results.hasOwnProperty('unscripted_speech_recognition')) {
      results['unscripted_speech_recognition'] = []
    }

    if (!results.hasOwnProperty('suggestionData')) {
      results['suggestionData'] = []
    }

    if (!results.hasOwnProperty('funcFinished')) {
      results['funcFinished'] = []
    }

    if (!results.hasOwnProperty('suggestionList')) {
      results['suggestionList'] = []
    }
    
    //check if it is already in the list
    for(let k in r['suggestionList'])
    {
      if (!results['suggestionList'].includes(r['suggestionList'][k])) {
        results['suggestionList'].push(r['suggestionList'][k])
        results['suggestionData'].push(r['suggestionData'][k])
        results['funcFinished'].push(r['funcFinished'][k])
      }
    }


    let exists = false
    for(let k of results['unscripted_speech_recognition'])
    {

      if (k['Name'].includes(r['unscripted_speech_recognition']['Name'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['unscripted_speech_recognition'].push(r['unscripted_speech_recognition'])
      // results['avg_sentence_results'].push(r['accuracy'])
    }


    // const sumWithInitial = results['avg_sentence_results'].reduce(
    //   (previousValue, currentValue) => previousValue + currentValue, 0
    // );
    
    // let avg_score = sumWithInitial/results['avg_sentence_results'].length;

    // results['avg_sentence_intel'] = 'is very good'
    // results['avg_sentence_interp'] = 'People can understand you well.'
    // if(avg_score < sentence_thresh_poor)
    // {
    //   results['avg_sentence_intel'] = 'needs improvement'
    //   results['avg_sentence_interp'] = "It's hard for people to understand what you mean."
    // } else if(avg_score < sentence_thresh_ok)
    // {
    //   results['avg_sentence_intel'] = 'is Ok'
    //   results['avg_sentence_interp'] = 'People can understand you with simple messages or familiar context.'
    // }
  }  
    
  if('real_life_exercise' in exercise_data)
  {

    if ('error' in exercise_data['real_life_exercise']) {
      return
    }

    if (!results.hasOwnProperty('real_life_exercise')) {
      results['real_life_exercise'] = []
    }

    let r = get_real_life_exercise_data(exercise_data, name)
    results['real_life_exercise'].push(r)
  }

  if('max_phonation_dictionary' in exercise_data)
  {

    if ('error' in exercise_data['max_phonation_dictionary']) {
      return
    }

    let r = getMax_phonation_data(exercise_data, name)
    
    if (!results.hasOwnProperty('maxphonationTable')) {
      results['maxphonationTable'] = [r]
    }
    
    let exists = false

    for(let k of results['maxphonationTable'])
    {
      if (k['AudioAddress'].includes(r['AudioAddress'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['maxphonationTable'].push(r)
    }

  }

  if('avqi' in exercise_data)
  {

    if ('error' in exercise_data['avqi']) {
      return
    }

    let r = get_avqi_data(exercise_data, name)

    if (!results.hasOwnProperty('avqi_table')) {
      results['avqi_table'] = []
    }
    
    results['avqi_table'].push(r)
  }

  if('jitter_shimmer_dictionary' in exercise_data)
  {
    if ('error' in exercise_data['jitter_shimmer_dictionary']) {
      return
    }


    let r = jitter_shimmer_data(exercise_data, name)
    
        
    if (!results.hasOwnProperty('jittershimmerTable')) {
      results['jittershimmerTable'] = [r]
    }
    
    let exists = false
    for(let k of results['jittershimmerTable'])
    {
      if (k['AudioAddress'].includes(r['AudioAddress'])) {
        exists = true
        break
      }
    }

    if (!exists) {
      results['jittershimmerTable'].push(r)
    }
  

  }

  if('f0_dictionary' in exercise_data)
  {

    if ('error' in exercise_data['f0_dictionary']) {
      return
    }

    if (!results.hasOwnProperty('pitchTable')) {
      results['pitchTable'] = []
    }

    var mF0 = exercise_data['f0_dictionary']['meanF0']
    var sF0 = exercise_data['f0_dictionary']['stdevF0']

    mF0 = roundNumber(mF0, 0);
    sF0 = roundNumber(sF0, 1);

    var audio_address = exercise_data['audio_address']

    var pitch = {Name: name, meanF0: mF0, stdevF0: sF0, AudioAddress:audio_address}

    results['pitchTable'].push(pitch)
  }

  if('session_questionnaire' in exercise_data)
  {
    if ('error' in exercise_data['session_questionnaire']) {
      return
    }

    if (!results.hasOwnProperty('session_questionnaire')) {
      results['session_questionnaire'] = []
    }

    let question = exercise_data['session_questionnaire']['question']
    let answer = exercise_data['session_questionnaire']['answer']
    let options = exercise_data['session_questionnaire']['options']

    let data = {question: question, answer: answer, options: options}

    results['session_questionnaire'].push(data)
  }
    
  if('error' in exercise_data)
  {
    if (!results.hasOwnProperty('error_table')) {
      results['error_table'] = []
    }
    
    var error_found = exercise_data['error']['error']

    var curr_struct = {Name: name, ErrorType: error_found};
    results['error_table'].push(curr_struct)
  }

  results = Object.assign({}, results, results)
  return results
}

export async function aggregatePlotData(exercise_type, exercises, results) {

  if (exercise_type === "Minimal Pairs") {

    let result_tags = Object.keys(exercises[0]['results']['plot_results']) //result keys 

    let localResults = []
    for(let k in exercises) {
      let exercise_plot_results = exercises[k]['results']['plot_results']

      let obj = {}
      for(let i = 0; i<result_tags.length; ++i) {
        let value = exercise_plot_results[result_tags[i]]
        let key_ = result_tags[i]

        obj[key_] = value        
      }
      localResults.push(obj)
    }

    results[exercise_type] = localResults
    results = Object.assign({}, results)

    return results
  }

  if (exercise_type === "Scene Description") {

    if (exercises.length > 0 && 'all_results' in exercises[0]['results']) {
    
      let result_tags = Object.keys(exercises[0]['results']['all_results']) //result keys ('correct', 'incorrect')
      
      console.debug('results_tags = ' + result_tags)
  
      let results_sum_by_result_tag = []
  
      for(let i = 0; i<result_tags.length; ++i) {
        results_sum_by_result_tag.push(0)
      }
  
      for(let k in exercises) {
        let exercise_plot_results = exercises[k]['results']['all_results']
  
        for(let i = 0; i<result_tags.length; ++i) {
          let value = exercise_plot_results[result_tags[i]]
  
          if(value) {
            results_sum_by_result_tag[i] += value //sum per key/tag
          }
        }
      }
  
  
      if (!results[exercise_type]) {
        results[exercise_type] = {}
      }
      
      for(let i = 0; i<result_tags.length; ++i) {
        if(isNaN(results_sum_by_result_tag[i]) || !results[exercise_type][result_tags[i]]) {
          results[exercise_type][result_tags[i]] = 0 //this should not happen
        } 
        results[exercise_type][result_tags[i]] += results_sum_by_result_tag[i]      
      }
  
  
   
  
      results = Object.assign({}, results)
      return results
    }

  }
  
  
  if (exercises.length > 0 && 'plot_results' in exercises[0]['results']) {
    
    let result_tags = Object.keys(exercises[0]['results']['plot_results']) //result keys ('correct', 'incorrect')
    
    console.debug('results_tags = ' + result_tags)

    let results_sum_by_result_tag = []

    for(let i = 0; i<result_tags.length; ++i) {
      results_sum_by_result_tag.push(0)
    }

    for(let k in exercises) {
      let exercise_plot_results = exercises[k]['results']['plot_results']

      for(let i = 0; i<result_tags.length; ++i) {
        let value = exercise_plot_results[result_tags[i]]

        if(value) {
          results_sum_by_result_tag[i] += value //sum per key/tag
        }
      }
    }


    if (!results[exercise_type]) {
      results[exercise_type] = {}
    }
    
    for(let i = 0; i<result_tags.length; ++i) {
      if(isNaN(results_sum_by_result_tag[i]) || !results[exercise_type][result_tags[i]]) {
        results[exercise_type][result_tags[i]] = 0 //this should not happen
      } 
      results[exercise_type][result_tags[i]] += results_sum_by_result_tag[i]      
    }


    //////////////////////TOP SCORES///////////////////////////
    let top_results_modules = [
      'Position Games', 
      'Antonym Exercise', 
      'Brain Games', 
      'Token Assessment', 
      'Pub Quiz', 
      'Reading Images', 
      'Word Search' 
    ] //move to data section

    if(top_results_modules.includes(exercise_type)) {
      results = await checkAgainstPersonalTopScores(exercise_type, results) //Top scores should be checked and logged during exercises
    }
    ////////////////////////////////////////////////////////////////////////////////////////

    results = Object.assign({}, results)
  }
  console.log(results)
  return results
}

export async function checkAgainstPersonalTopScores(exercise_name, results)
{
  

  let rt = new Promise((resolve) => {

    getUserDataDir('Top Results',function (err, data) {

      let curr_topic_top_results = data.child(exercise_name).val()

      console.debug('curr_topic_top_results = ' + curr_topic_top_results)

      if(curr_topic_top_results)
      { 
        let top_result = curr_topic_top_results['top_result']
        
        if(top_result > results['Correct'])
        {
          if('Total' in results)
          {
            let total = results['Total']
            
            if('Total' in curr_topic_top_results)
            {
              total = curr_topic_top_results.total
            }

            results[exercise_name]['top'] = top_result + ' correct answers' + ' (out of ' + total+')'
          } else {
            results[exercise_name]['top'] = top_result + ' correct answers'
          }

          let curr_result = {top_result: top_result, last_result: results[exercise_name]['Correct']}

          let folder_ref = data.child(exercise_name).ref
          updateData(folder_ref, curr_result)
        } else {
          let curr_result = {last_result: results[exercise_name]['Correct']}

          if('Total' in results[exercise_name])
          {
            curr_result.total = results[exercise_name]['Total']

            let total = results[exercise_name]['Total']

            results[exercise_name]['top'] = results[exercise_name]['Correct'] + ' correct answers' + ' (out of ' + total+')'+ '\n'
            results[exercise_name]['best_message'] = 'You just got a new best score!!!'
          } else { 
            results[exercise_name]['top'] = results[exercise_name]['Correct'] + ' correct answers \n'
            results[exercise_name]['best_message'] = 'You just got a new best score!!!'
          }

          let folder_ref = data.child(exercise_name).ref
          updateData(folder_ref, curr_result)
        }
      } else {
        let curr_result = {top_result: results[exercise_name]['Correct'], last_result: results[exercise_name]['Correct']}

        if('Total' in results[exercise_name])
        {
          curr_result.total = results[exercise_name]['Total']
          results[exercise_name]['top'] = results[exercise_name]['Correct'] +' correct answers' +' (out of ' + results[exercise_name]['Total']+')'+ '\n' 
        } else {
          results[exercise_name]['top'] = results[exercise_name]['Correct'] + ' correct answers \n' 
        }

        results[exercise_name]['best_message'] = 'You just got a new best score!!!'

        let folder_ref = data.child(exercise_name).ref

        updateData(folder_ref, curr_result)
      }
      resolve(results)
    })
  })

  return rt

}

export function getMetricPaths(firebaseHandle, target_key, _this) {
  if(firebaseHandle.key != target_key)
  {
    if(firebaseHandle.hasChildren())
    {  
      let all_results = []

      firebaseHandle.forEach(function (childSnapshot) {

        let curr_result = getMetricPaths(childSnapshot, target_key, _this)


        if(curr_result != null && curr_result.length > 0)
        {
  
          for (let result of curr_result) {
            all_results.push(result)
          }
        }
      });

      if(all_results.length>0)
      {
        return all_results
      }
    }
  }
  else {
    return [firebaseHandle.val()]
  }
  return null  
}

export function agregateForPlot(data, metric) {
      
  let plot_data = []
  if(metric === 'intelligibility' || metric === 'sentencegibility') {
    for (let date of Object.keys(data)) {
      let accuracy_score = 0
      let count = 0
      for (let i in data[date]) {
        if (data[date][i]['accuracy_score']) {
          accuracy_score = accuracy_score + data[date][i]['accuracy_score']
          count = count+1
        }
      }
      
      accuracy_score = accuracy_score / Math.max(count,1)

      if(accuracy_score>0)
      {
        plot_data.push({x: date, y: accuracy_score})

      }

    }
  }

  if(metric === 'speechrate_dictionary') {
    for (let date of Object.keys(data)) {
      let accuracy_score = 0
      let count = 0
      for (let i in data[date]) {
        if (data[date][i]['speechrate(nsyll by dur)']) {
          accuracy_score = accuracy_score + data[date][i]['speechrate(nsyll by dur)']
          count = count + 1
        }
      }
      
      accuracy_score = accuracy_score / Math.max(count,1)

      if(accuracy_score>0)
      {
        plot_data.push({x: date, y: accuracy_score})

      }

    }
  }

  if(metric === 'loudness') {
    for (let date of Object.keys(data)) {
      let loudness = 0
      let count = 0 
      for (let i in data[date]) {
        if (data[date][i]['loudness']) {
          loudness = loudness + data[date][i]['loudness']
          count = count + 1
        }
      }


      
      loudness = loudness / Math.max(count,1)

      if(loudness>0)
      {
        plot_data.push({x: date, y: loudness})

      }

    }
  }


  if(metric === 'ddk_rate_dictionary') {

    for (let date of Object.keys(data)) {
      let ddk_rate = 0
      let count = 0
      for (let i in data[date]) {
        if (data[date][i]['ddk_rate']) {
          ddk_rate = ddk_rate + data[date][i]['ddk_rate']
          count = count + 1
        }
      }
      
      ddk_rate = ddk_rate / Math.max(count,1)

      if(ddk_rate>0)
      {
        plot_data.push({x: date, y: ddk_rate})

      }

    }
  }

  if(metric == 'plot_results')
  {
    for (let date of Object.keys(data)) {
      let correct = 0
      let incorrect = 0
      for (let i in data[date]) {
        console.log(data[date][i])
        if (data[date][i].hasOwnProperty('Correct') && data[date][i].hasOwnProperty('Incorrect')) {
          console.log(date)
          correct = correct + data[date][i]['Correct']
          incorrect = incorrect + data[date][i]['Incorrect']
        }
      }
      
      let total = correct + incorrect

      let percent_correct = correct / total * 100

      plot_data.push({x: date, y: percent_correct})
    }
  }

  return plot_data
}

export function getPackageResultPath(is_assignment, assessment_type, package_uid) {

  let userType = localStorage.getItem('userCategory');
  let uid = localStorage.getItem('uid');

  let assessment_training = 'training'

  if(assessment_type.includes('ASSIGNMENT')) {
    is_assignment = true
  }

  if(assessment_type.includes('ASSESSMENT')) {
    assessment_training = 'clinic_assessment'

    if(is_assignment) {
      assessment_training = 'assignment_assessment'
    }
  } else if(is_assignment) {
    assessment_training = 'assignment_training'
  }
  

  let path = '/Users/'+ uid + '/Results/' + assessment_training + '/packages/' + package_uid
  

  if(userType=='slp')
  {
    let client_number = localStorage.getItem('client_uid');

    if(client_number!=null && client_number!='')
    {
      path = '/Users/'+ client_number + '/Results/' + assessment_training + '/packages/' + package_uid
      //path = '/Users/'+ uid + '/Clients/' + client_number + '/Results/' + assessment_training + '/packages/' + package_uid
    }
  }
  
  return path
}

export async function retrievePointedQuestions(exercise_type, exercises, localResults) {

  let _localResults = Object.assign({}, localResults)

  let promise = new Promise((resolve) => {

    if ( exercises.length > 0 && exercise_type === 'Questionnaire' &&
          'questionnaire_address' in exercises[0]['results']) {
      
      let questionnaire_address = exercises[0]['results']['questionnaire_address']
      let answers = exercises[0]['results']['chosen_options']

      let questionnaire_results = exercises[0]['results']
      
      let do_once = true 
      getDataDirectoryCallback(questionnaire_address + '/Ex', function (err, questions) {
        
        if(do_once) {
          do_once = false

          let temp= {
            'questions': questions.val().splice(0, answers.length), 
            'answers': answers,
            'exercises':[],
          }

          for(let k in questionnaire_results) {
            temp[k] = questionnaire_results[k]
          }

          _localResults[exercise_type] = temp

          console.debug(_localResults[exercise_type])
          resolve(_localResults)
        }

      })
    } else {
      resolve(_localResults)
    }
  })

  _localResults = await promise
  console.log(_localResults)
  return _localResults
}

export async function process_resultsTablePre(exercise_data, results)
{
  console.debug(exercise_data)

  let exercise_type = exercise_data['exercise_type'] //this is exercise name - so this variable needs to be renamed

  let exercise_name = exercise_data['exercise'] //subtype of exercise name  - not used here

  let exercise_results = exercise_data['results']

  let resultsDataPreTable = {}

  if (exercise_results) {
    
    if (!(exercise_type in resultsDataPreTable)) {
      resultsDataPreTable[exercise_type] = []
    }
    
    let exercise = {}
    exercise['results'] = exercise_results
    exercise['exercise'] = exercise_name

    resultsDataPreTable[exercise_type].push(exercise)
  }
  
  let localResults = Object.assign({}, results)
  console.log(resultsDataPreTable)
  for (const [exercise_type, exercises] of Object.entries(resultsDataPreTable)) {

    //read object as key - value (exercise_type - exercises)
    
    if(exercise_type === "Loudness") {
      for (let i in exercises) {
        localResults['loudness'] = loudnessScore(exercises[i]['results']['avg_loudness_array'])
      }
    }


    if(exercise_type === "Chat Scenario") {
      
      for (let i in exercises) {
        localResults['loudness'] = loudnessScore(exercises[i]['results']['avg_loudness_array'])

        if(!('chat_scenario' in localResults))
        {
          localResults['chat_scenario'] = []
        }

        localResults['chat_scenario'].push({'bot_topic':exercises[i]['results']['bot_topic'],'conversationHistory':exercises[i]['results']['conversationHistory']})

      }
    }
    
    
    if (exercise_type === 'Questionnaire') {
      if (exercises.length > 0 && 'exercise' in exercises[0]) {
        let exercise_name = exercises[0]['exercise']
        
        if (exercise_name === 'vhi') {
          let param = Object.assign({}, localResults)
          let _results = await retrievePointedQuestions(exercise_type, exercises, param)
          localResults = Object.assign({}, localResults, _results)
        }
      }
    }

    if (exercise_type === 'Questionnaire') {
      if (exercises.length > 0 && 'exercise' in exercises[0]) {
        let exercise_name = exercises[0]['exercise']
        
        if (exercise_name === 'la_trobe_self') {
          let param = Object.assign({}, localResults)
          let _results = await retrievePointedQuestions(exercise_type, exercises, param)
          localResults = Object.assign({}, localResults, _results)
        }
      }
    }

    if (exercise_type === 'Multipage Questionnaire') {
      if (exercises.length > 0 && 'exercise' in exercises[0]) {
        
        let exercise_name = exercises[0]['exercise']
        console.debug('exercise_name')

        console.debug(exercise_name)
        
        if (exercise_name === 'DIP' ) {

          if(!('Multipage Questionnaire' in localResults))
          {
            localResults['Multipage Questionnaire'] = {}
          }
          localResults['Multipage Questionnaire'][exercise_name] =  exercises[0]['results']
          
        }
      }
    }

    if (exercise_type === 'Selection Scales') {
      if (exercises.length > 0 && 'exercise' in exercises[0]) {
        
        let exercise_name = exercises[0]['exercise']
        console.debug('exercise_name')

        console.debug(exercise_name)
        
        if (exercise_name === 'fois' || exercise_name === 'dsfs' ) {

          if(!('Selection Scales' in localResults))
          {
            localResults['Selection Scales'] = {}
          }
          localResults['Selection Scales'][exercise_name] =  exercises[0]['results']
          
        }
      }
    }


    console.debug(localResults)

    if (exercise_type === 'Swal-Qol') {
      if (exercises.length > 0 && 'exercise' in exercises[0]) {
        let exercise_name = exercises[0]['exercise']
        
        if (exercise_name === 'Swal-Qol') {

          if(!('Questionnaire' in localResults))
          {

            localResults['Questionnaire'] = {}

          }

          let temp = {'Swal-Qol':exercises[0]['results']}
          localResults['Questionnaire'] = temp 
        }
      }
    }

    console.debug(localResults)

    if (exercise_type === 'FDA') {
      if (exercises.length > 0 && 'exercise' in exercises[0]) {
        let exercise_name = exercises[0]['exercise']
        
        if (exercise_name === 'FDA') {

          let temp = {'FDA':exercises[0]['results']}
          localResults['Questionnaire'] = temp 
        }
      }
    }

    console.debug(localResults)

    if(exercise_type === 'Pitch Range') {
      let _results = add_pitch_range_result(exercise_type, exercises, exercise_data['audio_address'], results)
      localResults = Object.assign({}, localResults, _results)
    }

    if (exercise_type === 'cats' && exercises.length > 0) {
      let _exercises = []
      for (let exercise of exercises) {
        for(let k in exercise['results']) {
          _exercises.push(exercise['results'][k])
        }
      }
      
      localResults[exercise_type] = _exercises
    }

    if (exercise_type === 'Dysphagia' && exercises.length > 0) {
      let _exercises = []
      for (let exercise of exercises) {
        for(let k in exercise['results']) {
          _exercises.push(exercise['results'][k])
        }
      }
      
      localResults[exercise_type] = _exercises
    }

    if (exercise_type === 'EMST') {
      let _exercises = []
      for (let exercise of exercises) {
        _exercises.push(exercise['results'])
      }
      
      localResults[exercise_type] = _exercises
    }

    let _results = await aggregatePlotData(exercise_type, exercises, results)
    localResults = Object.assign({}, localResults, _results)
  }
  console.debug('localResults')

  console.debug(localResults)

  return localResults
}

export async function post_process(results, session_type) {

  if (session_type === 'clinic'){
    
    let clinic_training_dir = '/Users/' + localStorage.getItem('client_uid') + '/Results/clinic_training/general/' 

    if ('EMST' in results) {
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      results['TODAY_EMST'] = {}  

      await new Promise((resolve, reject) => {
        getData(clinic_training_dir, function (err, result) {
          let total_sets = 0
          let total_repetitions = 0
          result.forEach(function(childSnapshot) {

            let checked_date = new Date(childSnapshot.key)
            checked_date.setHours(0, 0, 0, 0);

            if (checked_date.getTime() === today.getTime()) {
              if ('EMST' in childSnapshot.val()) {
                console.log(childSnapshot.val())

                let value = childSnapshot.val()

                for (let exercise_id in value['EMST']['exercises'])
                {
                  //console.log(value['EMST']['exercises'][exercise_id]['resultsTablePre']['results']['sets'])
                  total_sets += value['EMST']['exercises'][exercise_id]['resultsTablePre']['results']['sets']
                  total_repetitions += value['EMST']['exercises'][exercise_id]['resultsTablePre']['results']['repetitions']
                }
              }
            }

          });

          results['TODAY_EMST'] = {
            'sets': total_sets,
            'repetitions': total_repetitions
          } 
          
          resolve()
        });
      })
      
    }
  
    console.log(results)

  }

  return results
}