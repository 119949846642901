  
     


<template>
  <div class="row mt-2">

    <div>
      Chat Scenario 
    </div>




          <table class="table" style="table-layout: fixed">

            <colgroup>
              <col span="1" style="width: 25%;">
              <!-- <col span="1" style="width: 15%;"> -->

              <col span="1" style="width: 75%;">
            </colgroup>
            <thead>
                <tr>
                  <th scope="col">Topic</th>
                  <!-- <th scope="col">Loudness</th> -->
                  <th scope="col">Conversation</th>


                </tr>
              </thead>

          
            <tbody>
                <tr v-for="row, index in chat_scenario_data" v-bind:key="index">


                  <td>{{ row['bot_topic'] }}</td>

                  <td>

                    <div v-for="line,index2 in row['conversationHistory']">

                      <div v-if="line['role']==='assistant'" class="d-flex justify-content-start mb-3">
                        <div class="card p-3" style="background-color: #f0f0ff; border-left: 5px solid purple; border-radius: 20px 20px 20px 0; max-width: 75%;">
                          <p class="h5" style="white-space: pre-wrap; margin: 0;">
                            {{  line['content']  }}
                          </p>
                      </div>
                      </div>
                      <div v-else class="d-flex justify-content-end">
                        <div class="card p-3" style="background-color: #e8ffe8; border-left: 5px solid green; border-radius: 20px 20px 0 20px; max-width: 75%;">
                          <p class="h5" style="white-space: pre-wrap; margin: 0;">
                            {{  line['content']  }}
                          </p>
                      </div>
                      </div>


                    </div>
                  </td>


                 
                </tr>
               

              </tbody>
            </table>
    </div>





  

</template>
  
<script>

  import {ref, onUpdated, onMounted} from 'vue'


  export default {
    name: 'chat_scenario',
    components: {
    },
    props: {
      chat_scenario_data: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      

      onMounted( () => {


  
      });

    
      return {


        
      };

    }
  }
</script>