<template>
  <div class="row">
    <!-- <h1 class="display-5">FDA</h1> -->

    <!-- <h1 class="display-5">{{title}}</h1> -->
     <div class="col-2">
      <div class="card card-body">

        <div v-for="(dummy, d_index) in all_questionnaire_data" :key="d_index" class="mb-3">
  <!-- Collapsible Button with Purple Stripe -->
          <a 
            class="btn w-100 text-start py-2 px-3 border-0 bg-light d-flex align-items-center"
            :data-bs-toggle="'collapse'" 
            :href="'#collapseExample' + d_index" 
            role="button" 
            :aria-expanded="false" 
            :aria-controls="'collapseExample' + d_index"
            style="font-weight: bold; font-size: 0.9em; color: #5a3d99; border-radius: 0px;">
            
            <!-- Purple Stripe (narrow vertical line) -->
            <div v-if="domain_count!=d_index" style="width: 5px; height: 30px; background-color: #5a3d99; margin-right: 4px;"></div>
            <div v-else style="width: 5px; height: 30px; background-color: green; margin-right: 4px;"></div>

            <!-- Domain Name -->
            {{ all_questionnaire_data[d_index]['Domain'] }}
            <i class="bi bi-chevron-down ms-auto"></i>
            </a>

            <!-- Collapsible Section -->
            <div 
              class="collapse" 
              :id="'collapseExample' + d_index" 
              style="margin-left: 20px; padding-top: 10px;">
              
              <div v-for="(dummy2, question_index) in all_questionnaire_data[d_index]['Questions']" :key="question_index">
                
                <div @click="question_count=question_index; domain_count=d_index; loadQuestion(domain_count, question_count)">
                  
                  <!-- If not selected, normal card style -->
                  <div v-if="d_index != domain_count || question_index != question_count" class="card card-body mb-2" 
                    style="cursor: pointer; background-color: #f8f9fa; border: 1px solid #ddd; border-radius: 0px; font-size: 12px;">
                    {{ dummy2['name'] }}
                  </div>
                  
                  <!-- If selected, highlighted card style -->
                  <div v-else class="card card-body mb-2" 
                    style="cursor: pointer; background-color: #d6d6d6; border: 1px solid #ccc; border-radius: 0px; font-size: 12px;">
                    {{ dummy2['name'] }}
                  </div>
                </div>
                
              </div>
            </div>
          </div>



       

        
        
      </div>
     </div>
    
     <div class="col">
      <div class="card card-body">
        
        <p class="lead" style="text-align: left; font-size: 1.5em; font-weight: bold; color: rebeccapurple;margin-top: -1%;">{{ group_name }} > {{ question_name }}</p>
        <p class="lead" style="text-align: left; font-size: 1.2em;margin-top: -1%;">{{ instruct }}</p>
        <div v-for="row in sub_instruct">
          <p class="lead" style="text-align: left; font-size: 1em; margin-top: -1%;">{{ row }}</p>

        </div>
          <!-- <h8 style="text-align: left; font-size: 1.1em; color: green; margin-top: -1%;">{{ sub_instruct }}</h8> -->
          <!-- Need to add here for more details -->

          <div class="fda-card-body mt-1" v-for="row in this.qs.length" :key="row">
          <!-- Full row click handler -->
          <div class="row justify-content-start fda-clickable-row" @click="valueSelection(row-1)">
            <!-- Checkbox column -->
            <div class="col-1 fda-checkbox-column">
              <!-- Bind checkbox to the state -->
              <input
                type="checkbox"
                class="fda-checkbox"
                v-model="selections[row-1]"
                :checked="selections[row-1]"
              />
            </div>

            <!-- Option label column -->
            <div class="col-9">
              <div class="d-flex align-items-center fda-my-container">
                <div v-if="!selections[row-1]" class="fda-square d-flex justify-content-center align-items-center text-white">
                  {{ qs[row-1].value }}
                </div>
                <div v-else class="fda-square_select d-flex justify-content-center align-items-center text-white">
                  {{ qs[row-1].value }}
                </div>
                <div class="fda-rectangle d-flex justify-content-start align-items-center text-dark">
                  {{ qs[row-1].label }}
                </div>
              </div>
            </div>

            <!-- Show Details button (independent of checkbox toggle) -->
            <div class="col-2">
              <div v-if="qs[row-1].description !== undefined">
                <a class="fda-btn-link" @click.stop="toggle_click(row-1)">
                  Show details <i class="bi bi-chevron-down"></i>
                </a>
              </div>
            </div>
          </div>

          <!-- Description card -->
          <div class="fda-card-body mt-1" v-if="description_toggle[row-1] === true">
            {{ qs[row-1].description }}
          </div>
        </div>


        <div class="card card-body mt-2">
          Enter your observations
          <textarea class="w-100" type="text"></textarea>
        </div>

        <div class="row mt-4 justify-content-center">
          <div class="col-3" v-if="question_count>0 || domain_count>0">
            <button class="btn btn-primary btn-lg"  @click="goBack()"><i class="bi bi-chevron-left"></i> Previous</button>
          </div>
          <div class="col-3">
            <button class="btn btn-primary btn-lg"  @click="checkresult()"> Next <i class="bi bi-chevron-right"></i></button>
          </div>
        </div>
     </div>
    
  </div>
</div>

</template>
<script>

import {getUserDataDir, getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'

import {getTodayString} from '@/utils/utils'
import { Modal } from 'bootstrap'
import _ from 'lodash'
import { faL } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'fda',
  props: {
      exForm: {
        type: Object,
      },
    },
  data(){
    return {
      title:'',
      questionnaire_type:'',
      instruct:'',
      question_type:'A',
      questionnaire_data:{},
      questionnaire_address:'',
      sub_instruct:[],
      qn_size:10,
      results_dict:null,
      qs:[],
      rating:[],
      q_type:[],
      selections:[],
      radio_options: [],
      rate_scores:[0,1,2,3,4],
      population_mean: 0,
      population_std: 1,
      z_score: 0,
      z_score_meaning: '',
      showResults: false,
      form:{
        options:[]
      },
      msg_color: '',
      results_modal: '',
      question_count: 0,
      domain_count:0,
      all_responses:{},
      group_name:'',
      question_name:'',
      description_toggle:[]

    }
  },
  watch: {
    showResults(new_results, old_results) {
      if (new_results) {
          this.results_modal = new Modal(document.getElementById('results_modal'));
          this.results_modal.show();
      } else {
        if (this.results_modal) {
          this.results_modal.hide();
        }
      }
    }
  },
  async created() {
    await this.loadQuestionnaire();
  },
  methods: {
    async loadQuestionnaire()
    {
      let _this = this;

      this.questionnaire_address = this.exForm.questionnaire_address

    

      // let ref_questionnaires = _this.questionnaire_address
      let ref_questionnaires = '/Exercises/Resources/Frenchay_dysarthria_assessment'

      var do_once = true;
      let count = 0;
      _this.qs = [];
      _this.rating = []
      _this.q_type = []
      _this.radio_options = []

      return new Promise(resolve => {


      getData(ref_questionnaires , function (err, result) {

          if (do_once) {

            do_once = false;

            let curr_results = result.val()


            _this.all_questionnaire_data = []

            for(let k in curr_results)
            {
              let curr_questionnaire_data = curr_results[k]

              let new_questionnaire_data = {}

              let count = Number(k)+1
              new_questionnaire_data['Domain'] = curr_questionnaire_data['Domain']

              new_questionnaire_data['Questions'] = []

              console.debug('domain = '+ new_questionnaire_data['Domain'])

              let section_detail = curr_questionnaire_data['Sections']

              // console.debug('section = '+ section_detail[0]['question'])

              for(let l in section_detail)
              {

                let curr_question = {}

                curr_question['qs'] = section_detail[l]['choices']

                let selections = []
                for(let m in curr_question['qs'])
                {
                  selections.push(false)
                  
                }

                curr_question['selections'] = selections

                curr_question['name'] = section_detail[l]['name']
                curr_question['instruct'] = section_detail[l]['question']
                console.debug('instruct = '+ section_detail[l]['question'])
                
                if('instructions' in section_detail[l])
                {
                  curr_question['instruct'] = curr_question['instruct'] 

                  let temp_sub_instruct = section_detail[l]['instructions']

                  if(Array.isArray(temp_sub_instruct))
                  {
                    curr_question['sub_instruct'] = temp_sub_instruct

                  }
                  else
                  {
                    curr_question['sub_instruct'] = [temp_sub_instruct]

                  }
                    
                }

                new_questionnaire_data['Questions'].push(curr_question)

              }

              

              _this.all_questionnaire_data.push(new_questionnaire_data)

            }


            _this.loadQuestion(0,0)



            for(let i=0; i<_this.rate_scores.length;i++)
            {
                let input = {judge:'',score:i}
                _this.rating.push(input);
            }
            result.child('options').forEach(function (childSnapshot) {

              _this.rating[childSnapshot.val()].judge = childSnapshot.key;
              
            }); 

            

            resolve()
          }


        });
      })
    },
    loadQuestion(domain_index,question_index)
    {
      const _this = this

      window.scrollTo(0,0)

      let curr_domain = _this.all_questionnaire_data[domain_index]


      _this.group_name = curr_domain['Domain']

      let curr_question = curr_domain['Questions'][question_index]

      console.debug('curr_question')
      console.debug(curr_question)


      _this.instruct = curr_question['instruct']

      _this.sub_instruct = ''
      if('sub_instruct' in curr_question)
      {
        _this.sub_instruct = curr_question['sub_instruct']

      }

      _this.qs = curr_question['qs']
      _this.description_toggle = []
      for(let i in _this.qs)
      {
        _this.description_toggle[i] = false
      }
      console.debug('qs 0 = '+_this.qs[0]['label'])
      _this.question_name = curr_question['name']


      _this.selections = curr_question['selections']


      if('radio_options' in curr_question)
      {
        _this.radio_options = curr_question['radio_options']

      }
      
      _this.question_type = curr_question['question_type']
      

    },
    toggle_click(row_num)
    {
      this.description_toggle[row_num] = !this.description_toggle[row_num]
    },
    valueSelection(statement_id, selection_id) {

    
      console.debug('valueselection')
      {


        for(let k in this.selections)
        {
          this.selections[k] = false

        }
        this.selections[statement_id] = true


      }
    
    },
    goBack()
    {

      if(this.question_count>0)
      {
        this.question_count = this.question_count-1
      }
      else if(this.question_count==0 && this.domain_count>0)
      {
        this.domain_count = this.domain_count-1
        this.question_count = this.all_questionnaire_data[this.domain_count]['Questions'].length-1
      }

      

      this.loadQuestion(this.domain_count,this.question_count)

    },
    checkresult()
    {
        const _this = this

        

        let all_questions_answered = false


       

        if(all_questions_answered || true)
        {

          if(this.question_count+1<this.all_questionnaire_data[this.domain_count]['Questions'].length)
          {
               

            this.question_count = this.question_count+1

            this.loadQuestion(this.domain_count,this.question_count)
          }
          else
          {

            if(this.domain_count+1<this.all_questionnaire_data.length)
            {
              this.domain_count = this.domain_count+1
              this.question_count = 0
              
              this.loadQuestion(this.domain_count,this.question_count)

            }
            else
            {
              this.make_computations()

            }



          }

       
        }
        else
        {
          alert("There are still some unanswered questions. They all need to be filled before computing the Voice Handicap Index.")
        }

        // this.make_computations()

    },
    make_computations()
    {
      const _this = this

      let summarised_results = []

      let score_by_domain = {'Total':{'score':0,'total':0},'Domains':[]}
      let gone_domains = {}

      for(let domain_index in this.all_questionnaire_data)
      {

        for(let question_index in this.all_questionnaire_data[domain_index]['Questions'])
        {
          let curr_question = this.all_questionnaire_data[domain_index]['Questions'][question_index]
          let name = curr_question['name']
          let selection_index = 0

          let score = 9

          for(let k in curr_question['selections'])
          {
            if(curr_question['selections'][k]==true)
            {

              selection_index = k

              score = 9 - selection_index*2

            }
          }

          let domain = this.all_questionnaire_data[domain_index]['Domain']
          

          
          let curr_score_by_domain = {'score':0,'total':0,'Domain':domain}


          let index = -1
          if(gone_domains.hasOwnProperty(domain))
          {
            index = gone_domains[domain]
            curr_score_by_domain = score_by_domain['Domains'][index] 
          }
          else
          {
            gone_domains[domain] = Object.keys(gone_domains).length


          }



          console.debug(curr_score_by_domain)
          curr_score_by_domain.score = curr_score_by_domain.score+score
          curr_score_by_domain.total = curr_score_by_domain.total+9


          score_by_domain['Total'].score = score_by_domain['Total'].score+score

          score_by_domain['Total'].total = score_by_domain['Total'].total+9
          

          summarised_results.push({'name':name,'selection_index':selection_index,'Domain':domain,'score':score})


          if(index==-1)
          {
            score_by_domain['Domains'].push(curr_score_by_domain)
          }
          else
          {
            score_by_domain['Domains'][index] = curr_score_by_domain
          }

          console.debug(score_by_domain)
          

        }

      }

      this.results_dict = {}

      this.results_dict['Questionnaire_Name'] = 'FDA'

      this.results_dict['Score'] = score_by_domain

      this.results_dict['Summary'] = summarised_results

      this.results_dict['Data'] = this.all_questionnaire_data

      this.saveResults()


    },
    saveResults()
    {
      const _this = this

      if(_this.results_dict!=null)
      {



        var questionnaire_data = {resultsTablePre: {audio_address:'',exercise_type: 'FDA', exercise: 'FDA', results: _this.results_dict}}


        save_pre_computed_results(_this.exForm, questionnaire_data)


        // _this.bv_points.add(10)

        _this.$emit('logExerciseResults', questionnaire_data);
      }

      _this.$emit('nextExercise');
    },

    
  },
}
</script>
<style scoped>

/* Basic card styles */
.fda-card-body {
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

/* Adjust for checkbox column alignment */
.fda-checkbox-column {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.fda-checkbox {
  margin-right: 15px;
  transform: scale(1.2); /* Slightly larger checkbox */
}

/* Container around value and label */
.fda-my-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Space between checkbox-label and description */
}

/* Square element (purple by default) */
.fda-square {
  background-color: rebeccapurple;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.1em;
  text-align: center;
}




/* Show details link styling */
.fda-btn-link {
  text-decoration: none;
  font-weight: normal;
  color: rebeccapurple; /* Matching color of the show details text */
  font-size: 0.8em;
}

.fda-btn-link:hover {
  text-decoration: underline;
  color: rebeccapurple /* Change on hover for effect */
}

/* Card spacing and hover effect */
.fda-my-container:hover {
  background-color: #f0f0f0; /* Subtle hover effect for the container */
}

.fda-card-body.mt-2 {
  margin-top: 20px;
}

.fda-card-body.mt-1 {
  margin-top: 10px;
}

.fda-card-body .mt-2,
.fda-card-body .mt-1 {
  padding: 5px 10px;
}

/* Hover state on show details button */
.fda-btn-link i {
  margin-left: 5px;
}

/* Make the entire row clickable */
.fda-clickable-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  padding: 5px 10px;
  border-radius: 8px;
}


/* Checkbox styling */
.fda-checkbox {
  margin-right: 10px;
  width: 24px; /* Make checkbox bigger */
  height: 24px; /* Make checkbox bigger */
  cursor: pointer;
  accent-color: rebeccapurple; /* Style the checkbox */
}

/* Square element (purple by default) */
.fda-square {
  background-color: rebeccapurple;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}


/* Selected state of square (green background) */
.fda-square_select {
  background-color: green;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

/* Label styling */
.fda-rectangle {
  font-size: 1em;
  font-weight: normal;
  color: #333;
  margin-left: 15px; /* Add space between the square and the label */
}

/* Show details link styling */
.fda-btn-link {
  text-decoration: none;
  font-weight: normal;
  color: rebeccapurple; /* Matching color of the show details text */
  font-size: 1.1em;
}


.fda-btn-link:hover {
  text-decoration: underline;
  color: rebeccapurple; /* Change on hover for effect */
}

/* Card spacing and hover effect */
.fda-card-body {
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.fda-card-body.mt-2 {
  margin-top: 20px;
}

.fda-card-body.mt-1 {
  margin-top: 10px;
  padding: 5px 10px;
}


</style>