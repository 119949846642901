<template>
  <div class="row mt-2">



    <div v-if="'dsfs' in q_data" class="row mt-2" style="text-align: left;">



      <h4>Total Score - {{ q_data['dsfs']['total_score'] }}</h4>

          <div v-for="row,index in q_data['dsfs']['full_questionnaire']" v-bind:key=row class="col">
            
            <div v-if="row" class="card">

            <h5 style="color: rebeccapurple;">{{row['title']}}</h5>
            <h5>{{ row['answer']['label'] }} - Score {{ row['answer']['points'] }}</h5>
            </div>
          </div>
        </div>

     
    </div>
</template>

<script>

export default {
  name: "single_dsfs",
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
