<template>
  <div>
    <div v-if='intermediate_window_on' class="mt-4 mb-2">
      <div class="row">
        <p v-if="game_master_role" class="custom-text" >1. Now everyone close your eyes except for the Describer</p>
      </div>
      <div class="row justify-content-center" v-if="game_master_role">
        <img :src="listen_closeImg" style="width:280px;margin-top:0.5%;">
      </div>
      <div class="row">
        <p v-if="game_master_role" class="custom-text" >2. The Describer picks a difficulty level and clicks the 'Next' button below</p>
        <!-- <p v-if="game_master_role" class="custom-text" >3. Try to describe as many details as possible </p> -->
      </div>
      <div class="row">
        <p v-if="!game_master_role" class="custom-text">Now everyone open your eyes and click the 'Next' button below</p>
        <!-- <p v-if="!game_master_role" class="custom-text">2. Pick the image </p> -->
        <!-- <p v-if="!game_master_role" class="custom-text">3. Try to describe as many details as possible </p> -->
      </div>
      <div class="row justify-content-center text-center" v-if="!game_master_role">
        <img :src="listeningImg" style="width:280px;margin-top:0.5%;">
      </div>
      <div class="row justify-content-center text-center" v-if="game_master_role">
        <span style="color:#000000;">Difficulty level</span>
        <div class="row justify-content-center text-center">
          <div class="col-1">
            <p>Easy</p>
          </div>
          <div class="col-2">
            <input type="range" class="form-range" min="0" max="1" step="1" v-model="diffLevel" @change="updateDifficulty()">
          </div>
          <div class="col-1">
            <p>Hard</p>
          </div>
        </div>
      </div>
      
    <button  class="btn btn-info btn-lg mt-2 mb-2" @click="closeIntermediateWindow()">Next</button>
    </div>
    <div v-else>

      <p class="custom-text-main" style="margin:auto;text-align:center;margin-top:1%"> {{instructions_msg}}</p>
      <div class="row">
        <div class="p-1" v-if="!game_master_role" v-bind:style="{'width':'310px','margin':'auto','background':frameColour[0]}">
          <img  v-bind:src="first_img" @click="selectFirstImg()" width="300" height="300" style="object-fit: cover" >        
        </div> 
        <div class="p-1" v-if="!game_master_role" v-bind:style="{'width':'310px','margin':'auto','background':frameColour[1]}">
          <img  v-bind:src="second_img" @click="selectSecondImg()" width="300" height="300" style="object-fit: cover">
        </div>
        <div class="p-1" v-if="!game_master_role" v-bind:style="{'width':'310px','margin':'auto','background':frameColour[2]}">
          <img v-bind:src="third_img" @click="selectThirdImg()" width="300" height="300" style="object-fit: cover">
        </div>
      </div>

      <div class="row justify-content-center" v-if="game_master_role && !show_end ">
        <VTooltip v-if="game_diff=='Easy'"
            :triggers="['click']">
            <button class="btn btn btn-bd-yellow mb-2 rounded-pill" v-show="hintContent.length > 0">Tips</button>
            <template #popper>
              <p v-for="hint, index in hintContent" class="">
                {{index + 1}}. {{hint}}
              </p>
            </template>
        </VTooltip>
        <img v-bind:src="target_img" style="width:300px;height:300px;"/>

      </div>

      
      <div class="row">
        <div class="p-1" v-if="game_master_role && show_end" v-bind:style="{'width':'310px','margin':'auto','background':frameColour[0]}" >
          <img v-bind:src="first_img" width="300" height="300"   style="object-fit: cover" >        
        </div>
        <div class="p-1" v-if="game_master_role && show_end" v-bind:style="{'width':'310px','margin':'auto','background':frameColour[1]}" >
          <img v-bind:src="second_img" width="300" height="300" style="object-fit: cover">
        </div>
        <div class="p-1" v-if="game_master_role && show_end" v-bind:style="{'width':'310px','margin':'auto','background':frameColour[2]}" >
          <img v-bind:src="third_img" width="300" height="300" style="object-fit: cover">
        </div>
      </div>
    </div>

    <div class="row justify-content-center" v-if="game_finished">
      <p class="custom-text"> {{next_game_msg}}</p>
      <button class="btn btn-info btn-lg m-2 w-25" @click="goToNextGame()">Next game</button>
    </div>
    <div class="row justify-content-center" v-if="game_master_role && !intermediate_window_on">
      <button class="btn btn-info btn-lg m-2 w-25" @click="swapUser()">Next </button>
    </div>
  </div>

</template>
<script>
import homeImg from '@/assets/images/home.png'
import settingImg from '@/assets/images/setting.png'
import prePlansImg from '@/assets/images/prePlans.png'
import planImg from '@/assets/images/plan.png'
import warnImg from '@/assets/images/warn.png'
import addImg from '@/assets/images/add.png'
import listeningImg from '@/assets/images/listen_orig.jpg'
import listen_closeImg from '@/assets/images/listen_close.png'
import {getData } from '@/firebase/index'
import {logActivity,getTodayString } from '@/utils/utils'


export default {
  name: 'exercises',
  data() {
    return {
      instructions_msg:'',
      listeningImg:listeningImg,
      listen_closeImg:listen_closeImg,
      chosen_image: 0,
      show_end: false,
      next_game_msg: 'You can play a new game now',
      game_finished: false,
      image_folder_ref: '',
      image_folder_handle: '',
      intermediate_msg_game_master: "Instruction\n 1. You are the describer now.\n 2. You will see a picture in the next page.\n 3. Describe the picture as detailed as you can.\n 4. Now press Next.",
      intermediate_msg_game_listener: "Instruction\n 1. You are the listener now.\n 2. You will see three pictures in the next page.\n 3. Click on the one picture that you think is described by your game partner.",
      first_img: '',
      second_img: '',
      third_img: '',
      target_img: '',
      game_master_role: true,
      changing_img: homeImg,
      homeImg: homeImg,
      settingImg: settingImg,
      prePlansImg: prePlansImg,
      planImg: planImg,
      warnImg: warnImg,
      addImg: addImg,
      isTemplate: false,
      newPart: false,
      menuVisible: false,
      quickStart:'1. You and your friend will take turns to be the DESCRIBER or the LISTENER.\n 2. The DESCRIBER will describe what they see in the image.\n 3. Based on your description, the LISTENER will pick the corresponding image out of 3 options.',
      planName: '',
      btnClicked:false,
      image_selected1:false,
      image_selected2:false,
      image_selected3:false,
      waiting_click_image: true,
      game_set_handle: '',
      previous_sets: {},
      curr_category: [],
      curr_set_num: 0,
      curr_set_handle: [],
      num_categories: 0,
      target_img_number :0,
      game_diff:'Easy',
      diffLevel:0,
      diffMarks:{
        0:{
          style:{color: '#00000'},
          label:'Easy'
        },
        1:{
          style:{color: '#00000'},
          label:'Hard'
        },
      },
      categories:[],
      intermediate_window_on: true,
      nextGame:false,
      hintContent: [],
      styleCardWin: {
        width:'50%',
        margin: 'auto',
        position:'relative',
        top:'30px'
    },
    styleimgWin: {
        width:'30px',
        position:'absolute',
        top:'0.5%',
        right:'30px'
    },
    styleimgMob: {
        width:'20px',
        position:'absolute',
        top:'30px',
        right:'10px'
    },
    styleCardMob: {
        width:'100%',
        margin: 'auto',
        // position:'relative',
        // top:'50px'
    },
    styleBtn: {
        margin: 'auto',
        position:'relative',
        top:'50px'
    },
    styleBtn2: {
        margin: 'auto',
        position:'relative',
        top:'70px'
    },
    frameColour: ['#00000','#00000','#00000'],
    frameColour1: '#FF0000',
    frameColour2: '#FF0000',
    frameColour3: '#FF0000',
    styleCardSelected:
    {
        width:'310px',
        margin:'auto',
        background:this.frameColour
    },
    styleCardOrig:
    {
        width:'310px',
        margin:'auto',
        // background:'#fce5c6'
    },
    }
  },
 
  //
  created() {

    this.game_diff = this.diffMarks[0].label;
    console.debug('start diff! = '+this.game_diff)
    logActivity( 'accessed', 'image_game_1_player_in')
    this.newSetup()  
  },
  beforeUnmount()
  {
  },
  methods: {


    closeIntermediateWindow()
    {
      const _this = this
      _this.intermediate_window_on = false


      if(_this.game_master_role)
      {
         if(_this.game_diff == this.diffMarks[0].label)
          {
              _this.instructions_msg = '1. Describe what you see in the picture below.\n 2. Press the Next button.\n NOTE: if you need help, press the Tips button.';
          }
          else //for normal and hard
          {
              _this.instructions_msg = '1. Describe what you see in the picture below.\n 2. Press the Next button.';

          }    

          _this.loadImages();
      }

    },
    updateDifficulty()
    {
      this.game_diff = this.diffMarks[this.diffLevel].label;
    },
    swapUser()
    {
      this.intermediate_window_on = true
        this.game_master_role = !this.game_master_role

        if(!this.game_master_role)
        {
          this.instructions_msg = 'Based on the Describer\'s description, click on the picture you think is correct.';


        }
    },
    revealhints()
    {

    },
    answerFrame()
    {
      for(let i=0;i<this.frameColour.length;i++)
      {
        if(i == (this.target_img_number-1))
        {
          this.frameColour[i] = '#00FF00';
        }
        else if((i == (this.chosen_image-1))&&(i != (this.target_img_number-1)))
        {
          this.frameColour[i] = '#FF0000';
        }
        else
        {
          this.frameColour[i] = '#00000';
        }
      }
      
    },
    selectFirstImg()
    {
      var _this = this;



        _this.image_selected1 = true;
        _this.image_selected2 = false;
        _this.image_selected3 = false;

        _this.checkAnswer(1);
          
     
    },
    selectSecondImg()
    {
      var _this = this;


        _this.image_selected2 = true;
        _this.image_selected1 = false;
        _this.image_selected3 = false;

        _this.checkAnswer(2);

 

    },
    selectThirdImg()
    {
      var _this = this;

      
      _this.image_selected3 = true;
      _this.image_selected1 = false;
      _this.image_selected2 = false;



      _this.checkAnswer(3);
    

    },
    checkAnswer(chosen_img_number)
    {

      let _this = this;

      _this.waiting_click_image = false;

      _this.chosen_image = chosen_img_number;


 

        var correct_answer = _this.target_img_number;
        _this.target_img_number = correct_answer;



        if(correct_answer==chosen_img_number)
        {
            _this.instructions_msg = "You are correct!";

        }
        else
        {
            _this.instructions_msg = "That's not the correct image. Unlucky.";

        }

        _this.answerFrame();

        _this.game_finished = true;

        // _this.answerSubmitted();



    },
    goToNextGame()
    {
      var _this = this;

      _this.intermediate_window_on = true

      if(!_this.btnClicked)
      {
          _this.btnClicked = true
      }

      _this.newSetup();


      console.debug('go to next game')

    },
    newSetup()
    {


      const _this = this;

      _this.frameColour = ['#00000','#00000','#00000'];

      _this.nextGame = false;
      _this.game_finished = false

      _this.first_img = '';
      _this.second_img = '';
      _this.third_img = '';
      _this.target_img = '';

      _this.image_selected1 = false;
      _this.image_selected2 = false;
      _this.image_selected3 = false;

      _this.show_end = false;

      _this.chosen_image = 0;

      _this.game_master_role = true


      _this.game_master = true;


          // if(_this.game_diff == this.diffMarks[0].label)
          // {
          //     _this.instructions_msg = '1. Describe what you see in the picture below.\n 2. Press the Next button.\n NOTE: if you need help, press the Tips button.';
          // }
          // else //for normal and hard
          // {
          //     _this.instructions_msg = '1. Describe what you see in the picture below.\n 2. Press the Next button.';

          // }    

          // _this.loadImages();

        // }

      // });
    },

     randomiseCategorySet()
    {
      const _this = this

      let found_new_set = false


      let random_repeats = 0

      _this.num_categories = _this.categories.length
      while(!found_new_set)
      {

        let random_category = Math.floor(Math.random() * _this.num_categories)

        let num_sets = _this.game_set_handle.child(_this.categories[random_category]).numChildren()


        let random_set_number = Math.floor(Math.random() * (num_sets-1)) + 1

        
        random_repeats = random_repeats + 1




        

        let test =  _this.previous_sets[_this.categories[random_category]].includes(random_set_number)


        if(random_repeats < 20 & test)
        {


          found_new_set = false

          console.debug('repeat = ' + random_repeats)

        }
        else
        {

          found_new_set = true

          _this.curr_category = _this.categories[random_category]
          _this.curr_set_number = random_set_number

          console.debug('curr_category = ' + _this.curr_category)
          console.debug('_this.curr_set_number = ' + _this.curr_set_number)

        console.debug(' _this.previous_sets[_this.categories[random_category]] = ' +  _this.previous_sets[_this.categories[random_category]])


          _this.previous_sets[_this.categories[random_category]].push(random_set_number)
          _this.curr_set_handle = _this.game_set_handle.child(_this.categories[random_category]).child(random_set_number)

        }

      }



    },
    async loadImages()
    {

      const _this = this;   

      // _this.game_diff = 'easy'

      console.debug('here 1')

      if(_this.game_diff == this.diffMarks[1].label)
      {
        _this.image_folder_ref = _this.$firebase.database().ref("GameRoom").child('GameImages');
        var do_once = true;
        getData(_this.image_folder_ref , function (err, result) {

          if(do_once)
          {
            do_once = false;

            _this.image_folder_handle = result;


            var folder_n_sets = _this.image_folder_handle.numChildren();
            _this.curr_set_num = Math.floor(Math.random() * folder_n_sets ) +1

            let curr_set_num = _this.curr_set_num

            if(_this.curr_set_num==undefined || _this.curr_set_num==null || _this.curr_set_num<1)
            {
              curr_set_num = 1;
            }

              const num_images = 3;
              let random_image = Math.floor(Math.random() * num_images)+1; 

              _this.target_img_number = random_image;
              


              _this.target_img = result.child(curr_set_num).child(random_image).val();


              _this.first_img = result.child(curr_set_num).child('1').val();
              
              _this.second_img = result.child(curr_set_num).child('2').val();
              
              _this.third_img = result.child(curr_set_num).child('3').val();


          }


        });
      }
      else if(this.game_diff == this.diffMarks[0].label)
      {
        _this.image_folder_ref = _this.$firebase.database().ref("GameRoom").child('GameCategory');
        var do_once = true;

        await new Promise((resolve)=> {

          getData(_this.image_folder_ref , function (err, result) {
            if(do_once)
            {
              do_once = false;

              _this.image_folder_handle = result;
              _this.game_set_handle = result;


              if(_this.categories.length == 0)
              {

                console.debug('here 2')


                result.forEach(function (childSnapshot) {

                  _this.previous_sets[childSnapshot.key] = []


                  _this.categories.push(childSnapshot.key);
                });
              }


              _this.randomiseCategorySet()


              

    
                const num_images = 3;
                let random_image = Math.floor(Math.random() * num_images) + 1; 


                let category = _this.curr_category
                let curr_set_num =_this.curr_set_number

                _this.target_img_number = random_image;

                console.debug('category = ' + category)

                console.debug('curr_set_num = ' + curr_set_num)



                _this.first_img = result.child(category).child(curr_set_num).child('1').val();
                
                _this.second_img = result.child(category).child(curr_set_num).child('2').val();
                
                _this.third_img = result.child(category).child(curr_set_num).child('3').val();



                _this.target_img = result.child(category).child(curr_set_num).child(random_image).val();




              
              _this.hintContent = []
              result.child(category).child('Hints').forEach(function (childSnapshot) {

                _this.hintContent.push(childSnapshot.val());
              });
              resolve()
              // console.debug('hints: '+_this.hintContent);
            }
          });
        })
      }
      else
      {
        console.debug('tbp: level difficult for image loading');
      }
     
    },
    backhome() {
      this.$router.push({ name: 'groupActivities' })
    },
  },
}
</script>
<style scoped>
.custom-text
{
  font-size: 30px;
  color:#000000;
}

.custom-text-main
{
  font-size: 30px;
  color:#000000;
  white-space: pre-wrap;
  text-align: left;
  margin-left: 15%;
}


</style>