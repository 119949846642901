<template>
  <div class="row mt-2">



    <div v-if="'fois' in q_data" class="row mt-2" style="text-align: left;">



          <div class="col">
            <h5>{{ q_data['fois']['full_questionnaire']['1']['answer']['label'] }} - Score {{ q_data['fois']['total_score'] }}</h5>
          </div>
        </div>

     
    </div>
</template>

<script>

export default {
  name: "single_fois",
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
