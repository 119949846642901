<template>
  <div>
    <div class="card card-body mt-2">

      <div class="relative bg-white shadow-lg rounded-lg p-6 max-w-lg w-full">
        <span style="display:flex;justify-content:center;font-size:22px"> {{name}}</span>
      <div class="row mt-2 justify-content-center">

      <!-- Rocket Image -->
      <div class="absolute top-[-50px] left-1/2 transform -translate-x-1/2">


          <img :src="rocketImg" style="width:20%" >

      </div>

      <!-- Title -->
      <div class="text-center mt-16">
        <h1 class="text-2xl font-bold text-gray-800">Great work!</h1>
      </div>
      <!-- Body Content -->
      <div class="mt-4 text-center text-gray-600 text-lg">
        <p>
          You have finished <strong><span style="color: rebeccapurple;">{{ today_results['sets'] }}</span></strong> set out of
        <strong><span style="color: green;">5</span></strong>.
        </p>
        <p class="mt-2">
          Keep up the effort and finish the next <strong><span style="color: red;">{{5-today_results['sets']}}</span> </strong> now or later today.
        </p>
      </div>

     
      </div>
      </div>

    </div>
  </div>
</template>
  
<script>


import rocketImg from '@/assets/images/rocket.png'
import { result } from 'lodash';


  export default {
    name: 'emst',
    components: {},
    props: {
      session_results: {
        type: Object,
        default: null
      },
      today_results: {
        type: Object,
        default: null
      },
      name: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      let t_exercises = 0
      //const session_results = props.session_results[0]


      // const selected_features = props.selected_features

      // for(let k in selected_features)
      // {
      //   t_exercises = t_exercises+ results[k]
      // }

      // if(t_exercises<=0)
      // {
      //   t_exercises = 1
      // }

      // const total_exercises = t_exercises


      return {
        rocketImg
      };

    },
  }
</script>
