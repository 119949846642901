<template>
  <div >
    <h1 class="display-5">{{title}}</h1>
    <div class="card card-body">
      <div class="m-4">
      <p class="lead">{{ instruct }}</p>
      <div v-if="question_type == 'A'">
        <div class="card card-body mt-4" v-for="row in this.qs.length" :key="row">
          <h5>
              {{row}}. {{qs[row-1]}}
          </h5>
          <div class="row mt-4">
            <div class="col" v-for="col in radio_options.length" :key="col">
              <input type="radio" 
                class="btn-check" 
                :name="row" 
                :id="(row-1)*radio_options.length+col-1" 
                :checked="selections[row-1] === radio_options[col-1].value"
                autocomplete="off">
              <label class="btn btn-outline-primary" 
                :for="(row-1)*radio_options.length+col-1" 
                @click="valueSelection(row-1,col-1)">{{radio_options[col-1]['label']}}</label>
            </div>
          </div>
        </div>
  
      </div>
      <div v-if="question_type == 'B'" class="card card-body">
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="mb-4" v-for="row in this.qs.length" :key="row">
              <div class="d-flex align-items-center my-container" style="cursor: pointer;" @click="valueSelection(row-1)">

              <div v-if="selections[row-1]" class="square_select d-flex justify-content-center align-items-center text-white">
                {{qs[row-1].value}}
              </div>
              <div v-else class="square d-flex justify-content-center align-items-center text-white">
                {{qs[row-1].value}}
              </div>
              <div class="rectangle d-flex justify-content-center align-items-center text-dark">
                {{qs[row-1].label}}
              </div>
              </div>
              <!-- <h5>
                  {{row}}. {{qs[row-1]}}
              </h5>
              <div class="row mt-4">
                <div class="col" v-for="col in radio_options.length" :key="col">
                  <input type="radio" class="btn-check" :name="row" :id="(row-1)*radio_options.length+col-1" autocomplete="off">
                  <label class="btn btn-outline-primary" :for="(row-1)*radio_options.length+col-1" @click="valueSelection(row-1,col-1)">{{radio_options[col-1]['label']}}</label>
                </div>
              </div> -->
            </div>
          </div>
        </div>
   
      </div>
      <div class="row mt-4 justify-content-center">
        <div class="col-3" v-if="question_count>0">
          <button class="btn btn-primary btn-lg"  @click="goBack()"><i class="bi bi-chevron-left"></i> Previous</button>
        </div>
        <div class="col-3">
          <button class="btn btn-primary btn-lg"  @click="checkresult()"> Next <i class="bi bi-chevron-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>

import {getUserDataDir, getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'

import {getTodayString} from '@/utils/utils'
import { Modal } from 'bootstrap'
import _ from 'lodash'

export default {
  name: 'swal-qol',
  props: {
      exForm: {
        type: Object,
      },
    },
  data(){
    return {
      title:'',
      questionnaire_type:'',
      instruct:'',
      question_type:'A',
      questionnaire_data:{},
      questionnaire_address:'',
      qn_size:10,
      results_dict:null,
      qs:[],
      rating:[],
      q_type:[],
      selections:[],
      radio_options: [],
      rate_scores:[0,1,2,3,4],
      population_mean: 0,
      population_std: 1,
      z_score: 0,
      z_score_meaning: '',
      showResults: false,
      form:{
        options:[]
      },
      msg_color: '',
      results_modal: '',
      question_count: 0,
      all_responses:{},

    }
  },
  watch: {
    showResults(new_results, old_results) {
      if (new_results) {
          this.results_modal = new Modal(document.getElementById('results_modal'));
          this.results_modal.show();
      } else {
        if (this.results_modal) {
          this.results_modal.hide();
        }
      }
    }
  },
  async created() {
    await this.loadQuestionnaire();
  },
  methods: {
    async loadQuestionnaire()
    {
      let _this = this;

      this.questionnaire_address = this.exForm.questionnaire_address

    

      // let ref_questionnaires = _this.questionnaire_address
      let ref_questionnaires = '/Exercises/Resources/Swal_qol'

      var do_once = true;
      let count = 0;
      _this.qs = [];
      _this.rating = []
      _this.q_type = []
      _this.radio_options = []

      return new Promise(resolve => {


      getData(ref_questionnaires , function (err, result) {

          if (do_once) {

            do_once = false;

            let curr_results = result.val()


            _this.all_questionnaire_data = []

            for(let k in curr_results)
            {
              let curr_questionnaire_data = curr_results[k]

              let new_questionnaire_data = {}

              let count = Number(k)+1
              new_questionnaire_data['instruct'] = 'Q'+count+ '. ' + curr_questionnaire_data['instruction']

              if('statements' in curr_questionnaire_data)
              {
                new_questionnaire_data['question_type'] = 'A'
                new_questionnaire_data['qs'] = curr_questionnaire_data['statements']
                new_questionnaire_data['radio_options'] = curr_questionnaire_data['choices']
                new_questionnaire_data['Domain'] = curr_questionnaire_data['Domain']

         
                new_questionnaire_data['selections'] = []

                for(let k in new_questionnaire_data['qs'])
                {
                  new_questionnaire_data['selections'].push('')
                  
                }


              }
              else
              {
                new_questionnaire_data['question_type'] = 'B'
                new_questionnaire_data['qs'] = curr_questionnaire_data['choices']
                new_questionnaire_data['selections'] = []

                for(let k in new_questionnaire_data['qs'])
                {
                  new_questionnaire_data['selections'].push(false)
                  
                }

              }

              _this.all_questionnaire_data.push(new_questionnaire_data)
              
            }

            _this.loadQuestion(0)

            // let question_index = 'Q'+_this.question_count;

            // _this.questionnaire_data = curr_results[question_index]

            // _this.instruct = _this.question_count + '. '+ _this.questionnaire_data['instruction']

            // console.debug('instruction = '+_this.instruct)
            
            // if('statements' in _this.questionnaire_data)
            // {
            //   _this.question_type = 'A'
            //   _this.qs = _this.questionnaire_data['statements']
            //   _this.radio_options = _this.questionnaire_data['choices']
            //   // for(let option_id = 0; option_id<_this.qs.length;option_id++)
            //   // {
            //   //   _this.radio_options.push(_this.questionnaire_data['choices'])
            //   //   console.debug('radio options = '+_this.radio_options[option_id] + ' length = '+_this.radio_options.length)
            //   // }
            // }
            // else
            // {
            //   _this.question_type = 'B'
            //   _this.qs = _this.questionnaire_data['choices']

            //   for(let k in _this.qs)
            //   {
            //     _this.qs[k]['select'] = false
                
            //   }

            // }

            // _this.qn_size = _this.qs.length



              



            for(let i=0; i<_this.rate_scores.length;i++)
            {
                let input = {judge:'',score:i}
                _this.rating.push(input);
            }
            result.child('options').forEach(function (childSnapshot) {

              _this.rating[childSnapshot.val()].judge = childSnapshot.key;
              
            }); 

            

            resolve()
          }


        });
      })
    },
    loadQuestion(index)
    {
      const _this = this

      window.scrollTo(0,0)

      let curr_question = _this.all_questionnaire_data[index]


      _this.instruct = curr_question['instruct']
      _this.qs = curr_question['qs']

      _this.selections = curr_question['selections']


      if('radio_options' in curr_question)
      {
        _this.radio_options = curr_question['radio_options']

      }
      
      _this.question_type = curr_question['question_type']
      

    },
    valueSelection(statement_id, selection_id) {
      if(this.question_type=='A') {
        let value = this.radio_options[selection_id]['value']

        this.selections[statement_id] = value
        
        // Store in all_responses if needed
        if (!this.all_responses['q'+this.question_count]) {
          this.all_responses['q'+this.question_count] = {}
        }
        this.all_responses['q'+this.question_count][statement_id] = value
      }
      else if(this.question_type=='B')
      {
        for(let k in this.selections)
        {
          this.selections[k] = false

        }
        this.selections[statement_id] = true

      }
    
    },
    goBack()
    {
      this.question_count = this.question_count-1

      this.loadQuestion(this.question_count)

    },
    checkresult()
    {
        const _this = this

        

        let all_questions_answered = false

        if(this.question_type=='A') {

          all_questions_answered = true

          for(let k in this.selections)
          {
            if(this.selections[k]=='')
            {
              all_questions_answered = false
            }
          }
         
        }
        else if(this.question_type=='B')
        {
          for(let k in this.selections)
          {
            if(this.selections[k]== true)
            {
              all_questions_answered = true

            }

          }
        }

       

        if(all_questions_answered || true)
        {

          if(this.question_count+1<this.all_questionnaire_data.length)
          {
               

            this.question_count = this.question_count+1

            this.loadQuestion(this.question_count)
          }
          else
          {


            this.make_computations()

          }

       
        }
        else
        {
          alert("There are still some unanswered questions. They all need to be filled before computing the Voice Handicap Index.")
        }
    },
    make_computations()
    {
      const _this = this


      let score_overview = {'Domains':[],'Overall':0}

      let total_score = 0
      let total_domains = 0

      for(let k in _this.all_questionnaire_data)
      {

        let curr_question = _this.all_questionnaire_data[k]

        let domain = {'Name':curr_question['Domain']}
        console.debug(curr_question)

        console.debug(curr_question['Domain'])


        if(curr_question.question_type=='A')
        {
          let sum = 0
          let total = 0
          for(k in curr_question.selections)
          {

            console.debug('curr_question.selections[k] = ' + curr_question.selections[k])

            if(curr_question.selections[k]!='')
            {

              console.debug('curr_question.selections[k]! = ' + curr_question.selections[k])

              sum = sum+ 5-curr_question.selections[k]
              total = total + 4
            }

   
          }

          

          let mean = sum/Math.max(total,1)*10



          domain['score'] = mean

          total_score = total_score + mean
          total_domains = total_domains + 10

          score_overview.Domains.push(domain)

        }

        
      }


      total_score = total_score/total_domains*100

      score_overview.Overall = total_score

      if(total_score>=80)
      {
        score_overview.severity_level = 'Low-Mild Impact'

      }else if(total_score>=50)
      {
        score_overview.severity_level = 'Moderate Impact'

      }
      else if(total_score>=0)
      {
        score_overview.severity_level = 'Severe Impact'

      }

      this.results_dict = {}

      this.results_dict['Questionnaire_Name'] = 'Swal-Qol'

      this.results_dict['Score'] = score_overview

      this.results_dict['Data'] = this.all_questionnaire_data


      this.saveResults()


    },
    saveResults()
    {
      const _this = this

      if(_this.results_dict!=null)
      {



        var questionnaire_data = {resultsTablePre: {audio_address:'',exercise_type: 'Swal-Qol', exercise: 'Swal-Qol', results: _this.results_dict}}


        save_pre_computed_results(_this.exForm, questionnaire_data)


        // _this.bv_points.add(10)

        _this.$emit('logExerciseResults', questionnaire_data);
      }

      _this.$emit('nextExercise');
    },

    
  },
}
</script>
<style scoped>


.square {
  width: 70px;          /* Make the square a fixed width */
  height: 80px;         /* Height equal to width for a square shape */
  background-color: #003366; /* Dark blue color */
  margin-right: 0px;    /* Space between square and rectangle */
}


.square_select {
  width: 70px;          /* Make the square a fixed width */
  height: 80px;         /* Height equal to width for a square shape */
  background-color: rebeccapurple; 
  margin-right: 0px;    /* Space between square and rectangle */
}

.rectangle {
  flex: 1;               /* Take remaining width available */
  height: 80px;         /* Same height as the square */
  background-color: #add8e6; /* Light blue color */
}

.page_title {
  width: 100%;
  height: 40px;
  background-color: #7cc543;
}
.page_main {
  width: 100%;
  height: calc(100vh - 40px);
  background-color: #195e52;
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 40px;
  margin-left: 2%;
  font-size: 30px;
}
.el-menu-item{
  font-size:20px;
}

</style>