<template>
  <div>
    <div class="card card-body mt-2">

      <div class="relative bg-white shadow-lg rounded-lg p-6 max-w-lg w-full">
        <span style="display:flex;justify-content:center;font-size:22px"> {{name}}</span>
      <div class="row mt-2 justify-content-center">

      <!-- Rocket Image -->
      <div class="absolute top-[-50px] left-1/2 transform -translate-x-1/2">


          <img :src="rocketImg" style="width:20%" >

      </div>

      <!-- Title -->
      <div class="text-center mt-16">
        <h1 class="text-2xl font-bold text-gray-800">Great work!</h1>
      </div>

      <!-- Body Content -->
      <div class="mt-4 text-center text-gray-600 text-lg">
        <p v-if="results[0]==1">
          You have finished <strong><span style="color: rebeccapurple;">{{ results[0] }}</span></strong> repetition.
        </p>
        <p v-else>
          You have finished <strong><span style="color: rebeccapurple;">{{ results[1] }}</span></strong> repetitions.
        </p>
        <p class="mt-2">
          Keep on the hard work!
        </p>
      </div>

     
      </div>
      </div>

    </div>
  </div>
</template>
  
<script>


import rocketImg from '@/assets/images/rocket.png'
import { result } from 'lodash';


  export default {
    name: 'dysphagia',
    components: {},
    props: {
      results: {
        type: Array,
        default: {}
      },
      name: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      let t_exercises = 0
      const results = props.results
      // const selected_features = props.selected_features

      // for(let k in selected_features)
      // {
      //   t_exercises = t_exercises+ results[k]
      // }

      // if(t_exercises<=0)
      // {
      //   t_exercises = 1
      // }

      // const total_exercises = t_exercises


      return {
        results,
        rocketImg
      };

    },
  }
</script>
