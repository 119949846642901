<template>
  <div class="row mt-2">
    

    <div class="card">


 
      <div v-if="'fois' in q_data">

        <div class="col">
          <h4>Functional Oral Intake Scale</h4>
        </div>


        <Single_fois
              :q_data="q_data['fois']">
          </Single_fois>
          
        </div>

        <div v-if="'dsfs' in q_data">

        <div class="col">
          <h4>Drooling Severity and Frequency Scale</h4>
        </div>




        <Single_dsfs
              :q_data="q_data['dsfs']">
          </Single_dsfs>
          
        </div>
        
        </div>

    </div>
</template>

<script>
import { ref, onUpdated, computed, onMounted } from "vue";



import Single_fois from "../../../dashboard/slt/client_interface/pages/case_notes/components/fois/single_fois.vue";
import Single_dsfs from "../../../dashboard/slt/client_interface/pages/case_notes/components/dsfs/single_dsfs.vue";


export default {
  name: "selection_scales",
  components:{

    Single_fois,
    Single_dsfs
  },
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
  methods: {
   
  },
  setup(props) {
    

    return {
    };
  },
};
</script>

<style>

</style>
