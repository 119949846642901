<template>
  <div>

  

    <chat_scenario 
      :chat_scenario_data="results['chat_scenario']" 
      v-if="results['chat_scenario']"></chat_scenario>
 
  </div>
</template>    

<script>
import {ref, onUpdated, onMounted} from 'vue'
import chat_scenario from '../type/chat_scenario.vue';


export default {
    name: 'chat_scenario_section',
    components: {
      chat_scenario,
    },
    props: ['results'],
    setup(props) {



      onMounted( () => {


      });

      return {
        
      };

    }
  }
  </script>