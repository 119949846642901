import axios from 'axios';
import { getAuthToken } from '@/firebase/index';
import RecordRTC from 'recordrtc';


export class BV_VideoRecorder {
  constructor(liveVideoElement, live_stream_before_record=false) {
    this.save_media_destination_address = null;
    this.live_stream_before_record = live_stream_before_record
    this.stream = null;

    this.liveVideoElement = liveVideoElement;

    this.recordingPromise = this.setupRecordingPromise();

    if (live_stream_before_record) {
      this.connect_to_component()
    }
  }

  getSupportedMimeType() {
    const mimeTypes = [
      'video/webm;codecs=opus,vp8',
      'video/webm;codecs=vp9',
      'video/webm;codecs=vp8',
      'video/webm',
      'video/mp4',
    ];
  
    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        return mimeType;
      }
    }
  
    return 'video/webm'; 
  }

  async checkPermissionsMedia() {
    let constraints = { audio: true, video: true };

    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert('Your browser does not support getUserMedia. Please use a modern browser.');
        return;
      }
      if ( !this.stream ) {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      }

    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  }

  async connect_to_component() {
    await this.checkPermissionsMedia()
    this.liveVideoElement.srcObject = this.stream;
    this.liveVideoElement.play();
    console.log('connected to live video element')
  }

  record(save_media_destination_address) {
    if (!this.stream) {
      alert('Could not get local stream from mic/camera');
      return;
    }
    const mimeType = this.getSupportedMimeType();
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: mimeType,
    });

    this.save_media_destination_address = save_media_destination_address

    if (!this.live_stream_before_record) {
      this.connect_to_component()
    }

    this.recorder.startRecording();
    console.debug('Start recording...');
  }

  stop() {
    if (!this.recorder) {
      console.debug('No active recorder found.');
      return;
    }

    console.debug('Stop recording...');

    this.recorder.stopRecording(() => {
      // Get the recorded blob
      const recordedBlob = this.recorder.getBlob();
      console.log('Recording finished, Blob ready for upload.');
      console.log(recordedBlob)
      this.uploadRecording(recordedBlob);
    });


  }

  async uploadRecording(blob) {
    try {
      const form = new FormData();
      const url = import.meta.env.VITE_FAST_API + '/video_conversion';

      form.append('payload', blob);
      form.append('path', this.save_media_destination_address)
          

      const idToken = await getAuthToken();

      await axios.post(url, form, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Recording successfully uploaded!');
      this.resolveRecordingPromise();

    } catch (error) {
      console.error('Error uploading recording:', error);
      this.rejectRecordingPromise(error);
    } finally {

      this.destroy()
    }
  }

  destroy() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }

    if (this.liveVideoElement) {
      this.liveVideoElement.srcObject = null;
    }

    if (this.recorder) {
      this.recorder.destroy();
      this.recorder = null;
    }
  }

  pause() {
    if (this.recorder) {
      console.debug('Pause recording...');
      this.recorder.pauseRecording();
    }
  }

  resume() {
    if (this.recorder) {
      console.debug('Resume recording...');
      this.recorder.resumeRecording();
    }
  }

  reset() {
    console.debug('Reset recording...');
    this.cleanup();
  }

  setupRecordingPromise() {
    return new Promise((resolve, reject) => {
      this.resolveRecordingPromise = resolve;
      this.rejectRecordingPromise = reject;
    });
  }
}
