<template>
  <div class="row mt-2">


    <div v-if="'DIP' in q_data" class="row mt-2" style="text-align: left;">


      

      <h5>Total score - {{q_data['DIP']['total_score']}} </h5>

      <hr style="height:2px;border:none;color:#333;background-color:green;" />

            <div class="mt-4">
            <div  class="row" style="text-align: left;">
              <p class="lead">DIP by section:</p>

            </div>
            <div  class="col">
              
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Section</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row, index in q_data['DIP']['Score']['Domains']" v-bind:key="index" >
                  <td>{{row['section_name']}}</td>
                  <td>{{row['total_score']}}/{{ row['max_score'] }}</td>
                </tr>
               
               
              </tbody>
            </table>
          </div>
          </div>

          <!-- <div class="col">
            <h5>{{ q_data['fois']['fois']['full_questionnaire']['1']['options'][q_data['fois']['fois']['full_questionnaire']['1']['selectedOption']-1]['label'] }} - Score {{ q_data['fois']['fois']['total_score'] }}</h5>
          </div> -->
        </div>

        <hr style="height:2px;border:none;color:#333;background-color:green;" />

        <div v-for="page,index in q_data['DIP']['full_questionnaire']" v-bind:key="index">

          <p class="lead" style="text-align: left; font-size: 1em; font-weight: bold; color: rebeccapurple;">{{page['title']}}</p>

          <div v-for="question,index2 in page['questions']" v-bind:key="index2">

            <div v-if="question['type'] === 'multi_question_multi_selection'">

              <table class="table mt-4" >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Question</th>
                    <th scope="col">Score</th>

                    <th scope="col">Answer</th>
                  </tr>
                </thead>
                <tbody>

              <tr class="mb-4" v-for="row,mq_index in question['questions']" :key="mq_index">

                <td>{{ mq_index +1 }}</td>
                <td class="text-start">{{ row['question']}}</td>
                <td class="text-start">{{ row['answer']['value'] }}</td>
                <td class="text-start">{{ row['answer']['label'] }}</td>

                </tr>
              </tbody>
            </table>
            </div>

            <div v-if="question['type'] === 'dip_section_e'">

              <div class="mb-4">
                <h4>5 things that you are most concerned about.</h4>

                <p class="lead">1 Most concerned/most worried about: {{ question['questions'][1]['answers'][0]}}</p>
                <p class="lead">2 Concerned/very worried about: {{ question['questions'][1]['answers'][1]}}</p>
                <p class="lead">3 Concerned/worried about: {{ question['questions'][1]['answers'][2]}}</p>
                <p class="lead">4 Slightly concerned/slightly worried about: {{ question['questions'][1]['answers'][3]}}</p>
                <p class="lead">5 Least concerned/worried about: {{ question['questions'][1]['answers'][4]}}</p>

              </div>

              <div class="mb-4">
                <h4>Form was answered by: {{ question['questions'][2]['answers'][0] }} </h4>

              </div>

            </div>


          </div>



        </div>

     
    </div>
</template>

<script>

export default {
  name: "single_dip",
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
