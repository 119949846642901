<template>
<div class="text-start">
  <div class="row mb-4">
    <div class="col-md-4">
      <div class="card-body divH text-center border border-dark border-opacity-25" style="cursor:pointer" @click="describingPictureGame()">
        <img :src="describing_pictures" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong class="d-inline">Describing Pictures</strong>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card-body divH text-center border border-dark border-opacity-25" style="cursor:pointer" @click="findingWordsGame()">
        <img :src="finding_words" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Finding Words</strong>
      </div>
    </div>
  </div>
</div>
</template>


<script>

import describing_pictures from '@/assets/images/dashboard/slt/group_games/GroupGames_DescribingPictures.svg'
import finding_words from '@/assets/images/dashboard/slt/group_games/GroupGames_FindingWords.svg'


export default {
  name: 'Assessment',
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      region:'',
      finding_words:finding_words,
      describing_pictures:describing_pictures,
    


    }
  },
  created() {
    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');

    localStorage.setItem("view_mode", "assessment")
  },
  methods: {
    describingPictureGame()
    {
      const _this = this

      _this.$router.push({ name: 'image_game' });
    },
    findingWordsGame()
    {
      const _this = this

      _this.$router.push({ name: 'word_game' });


    }
  },
}
</script>
<style scoped>

</style>