<template>
  <div>
  <div id="add_patient_modal" class="modal modal-lg"  data-bs-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add patient</h5>
        </div>
        <div class="modal-body">
          <div v-show="registering">
            <p class="lead">Link a patient to your account to review their progress, generate case notes, assign home exercises, and listen to their performance audio.</p>
            <div class="row mb-4">
              <FormKit type="form" :actions="false" classs="mt-5" id="formPatient" @submit="add_patient()">
                <div class="row g-3">
                  <div class="col">
                    <label class="form-label" for="firstname">Name <span style="color:red">*</span></label>
                    <FormKit input-class="form-control" id="firstname" name="name" v-model="firstname" @keyup="do_not_use_email = false"
                    :sections-schema="{
                    messages: { $el: 'div' },
                    message: { $el: 'span' },
                    options: { $el: 'div' },
                    option: { $el: 'div' },
                    }"
                    messages-class="text-danger"
                    validation="required" />
                  </div>
                  <div class="col">
                    <label class="form-label" for="surname">Surname <span style="color:red">*</span></label>
                    <FormKit input-class="form-control" id="surname" name="surname" v-model="surname" @keyup="do_not_use_email = false" 
                    :sections-schema="{
                    messages: { $el: 'div' },
                    message: { $el: 'span' },
                    options: { $el: 'div' },
                    option: { $el: 'div' },
                    }"
                    messages-class="text-danger"
                    validation="required" />
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="form-label" for="email">Email <span style="color:red">*</span></label>
                  <div class="row">
                    <div class="col-8 mb-4">
                      <FormKit input-class="form-control" 
                      id="email" 
                      type="email" 
                      name="email" 
                      v-model="email" 
                      :disabled="do_not_use_email || firstname == '' || surname == ''"
                      :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                      }"
                      messages-class="text-danger"
                      :validation="!do_not_use_email ? 'required | email' : ''"/>
                    </div>
                    <div class="col-4 d-flex align-items-start">
                      <p class="mt-2">Do not use email</p>
                      <input class="ms-2 form-check-input mt-1" type="checkbox" v-model="do_not_use_email" @click="confirm_no_email()"  :disabled="firstname == '' || surname == ''"/>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="do_not_use_email">
                  <p>Choose unique account ID: </p>
                  <div class="input-group">
                    <div class="form-check">
                      <label class="form-check-label" for="uniqueId_name">{{uniqueId_name}}</label>
                      <input class="form-check-input" type="radio" v-model="account_auth_id" :value="uniqueId_name">
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="form-check">
                      <label class="form-check-label" for="uniqueId_fruit">{{uniqueId_fruit}}</label>
                      <input class="form-check-input" type="radio" v-model="account_auth_id" :value="uniqueId_fruit">
                    </div>
                  </div>                  
                </div>

                <div class="row">
                  <p>Select communication/swallowing challenges for your patient 
                    <span style="display: inline-block; margin-left: 5px;">
                    <VTooltip placement="right">
                      <i class="bi bi-question-circle"></i>
                      <template #popper>
                        <p style="width: 400px;">
                          We use this information to make sure the right type of exercises are shown to your patient on their platform.
                        </p>
                      </template>
                    </VTooltip>
                  </span>
                  </p>
                  <div class="row">
                    <div class="col">
                      <div class="input-group">
                        <div class="form-check">
                          <label class="form-check-label" for="uniqueId_name">Slurred/choppy speech</label>
                          <input class="form-check-input" type="checkbox" v-model="selected_comm_difficulties" :value="comm_list[0]">
                        </div>
                      </div>
                      <div class="input-group">
                        <div class="form-check">
                          <label class="form-check-label" for="uniqueId_fruit">Hoarse/breathy voice</label>
                          <input class="form-check-input" type="checkbox" v-model="selected_comm_difficulties" :value="comm_list[1]">
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group">
                        <div class="form-check">
                          <label class="form-check-label" for="uniqueId_name">Difficulty in moving lips, jaw or tongue</label>
                          <input class="form-check-input" type="checkbox" v-model="selected_comm_difficulties" :value="comm_list[2]">
                        </div>
                      </div>
                      <div class="input-group">
                        <div class="form-check">
                          <label class="form-check-label" for="uniqueId_fruit">Dysphasia/aphasia</label>
                          <input class="form-check-input" type="checkbox" v-model="selected_comm_difficulties" :value="comm_list[3]">
                        </div>
                      </div>
                    </div>
               
                  </div>
                  <div class="col">
                      <div class="input-group">
                        <div class="form-check">
                          <label class="form-check-label" for="uniqueId_name">Dysphagia</label>
                          <input class="form-check-input" type="checkbox" v-model="selected_comm_difficulties" :value="comm_list[4]">
                        </div>
                      </div>
                      </div>
                </div>

              </FormKit>
            </div>
            <div class="form-check" >
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="agreement">
              <label class="form-check-label" for="flexSwitchCheckChecked"><p>I consent to sharing the above information with Beautiful Voice for the purpose of creating an account for this patient if they do not already have one. 
                Please read Beautiful Voice's <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/terms%2FBV_TERMS.pdf?alt=media&token=602cef8d-fb54-4dc7-bd16-9ba44215689c">Terms of Use</a> and 
                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/terms%2FPrivacy%20Policy.pdf?alt=media&token=a8132518-3a58-405d-8f6b-f0616af2ebff">Privacy Policy</a>.</p></label>
            </div>
          
          </div>
          <div v-if="!registering">
            <div v-if="error_registration">
              <h5 style="text-align:left">There has been an error when adding the patient</h5>
              <p>Please contact Beautifulvoice to resolve the issue. 
                <br/> Contact email: <a href="mailto:support@beautifulvoice.co.uk">support@beautifulvoice.co.uk</a></p>  
            </div>
            <div v-else>
              <h5 class="text-start">You have successfully linked a patient {{ firstname }} {{ surname }}</h5>

              <div v-if="new_account_created == true">
                <h5 style="text-align:left">Please provide these credentials to the patient to login: </h5>
                <div style="border:1px solid black;">
                  <h5>Email/Account ID: <i>{{ do_not_use_email ? account_auth_id: email }}</i> </h5>
                  <h5>Password: <i>{{ new_password }}</i> </h5>
                </div>                
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="registering">
            <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal" @click="closeRegistration()">Cancel</button>
            <button v-if="!loading" form="formPatient" :disabled="!agreement" class="btn btn-success" type="submit">Register</button>
            <button v-else :disabled="true" type="button" class="btn btn-success"><font-awesome-icon icon="spinner" class="fa-spin" /> Processing...</button>
          </div>
          <div v-else>
            <button class="btn btn-success" type="button" data-bs-dismiss="modal" @click="closeRegistration()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="no_email_confirmation_modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">No Email</h5>
        </div>
        <div class="modal-body">
          <p>You are not using an email for this account.</p>
          <p>Please note, in this case, the user will not receive any communication from the therapist (e.g., homework notification), or be able to reset their account password if forgotten, or be shared with other therapists.
              For more information, <a href="mailto:contact@beautifulvoice.co.uk">contact@beautifulvoice.co.uk</a> </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" @click="do_not_use_email = false">Cancel</button>
          <button type="button" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close" @click="do_not_use_email = true">I understand</button>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>

import { useToast } from "vue-toastification";
import { updateDataDirectory, getAuthToken} from '@/firebase/index'
import { Modal } from 'bootstrap'

export default {
    name: 'add_patient',
    props: {
      show: {
        type: Boolean,
      },
    },
    data() {
        return {
          api: import.meta.env.VITE_FAST_API + '/add_patient',
          no_email_confirmation_modal: null,
          do_not_use_email: false,
          loading: false,
          registering: true,
          agreement: false,
          firstname: '',
          surname: '',
          account_auth_id: null,
          uniqueId_name: '',
          uniqueId_fruit: '',
          email: '',
          new_account_created: false,
          error_registration: false,
          new_password: null,
          selected_comm_difficulties: [],
          comm_list: [
          'Slurred/choppy speech',
          'Hoarse/breathy voice',
          'Difficulty in moving lips, jaw or tongue',          
          'Dysphasia/aphasia',
        'Dysphagia']
      }
    },
    mounted() {
    },
    methods: 
    {
      async add_patient() {

        let _this = this;
        if (this.agreement) {

          _this.loading = true

          //check if email exists in database already
          let uid = localStorage.getItem('uid')

          let cpromise = await new Promise( (resolve) => {
            _this.$firebase.database().ref('Users').child(uid).child('Clients').get().then((result)=>{
              let already_exist_in_client_list = false

              if (result.exists()) {
                for (let key in result.val()) {
                  if ('account_email' in result.val()[key] && result.val()[key]['account_email'] === _this.email) {
                    already_exist_in_client_list = true
                    break
                  }
                  if ('account_auth_id' in result.val()[key] && result.val()[key]['account_auth_id'] === _this.account_id) {
                    already_exist_in_client_list = true
                    break
                  }
                }
              } else {
                console.debug("No data available");
                resolve(false)
              }

              if (already_exist_in_client_list) {
                alert("Patient is already added. Please add a new patient with a different email address.")
                this.closeRegistration()
                resolve(false)
              }

              resolve(true)
            });
          })

          if (!cpromise) {
            return
          }

          let postData = {
              slt_uid: uid,
              account_auth_id: _this.do_not_use_email ? _this.account_auth_id: _this.email,
              firstname: _this.firstname,
              surname: _this.surname,
              agreement: _this.agreement,
              comm_difficulties: _this.selected_comm_difficulties,
              resend: "false" //set this to false always here
            }
          
          getAuthToken().then((idToken) => {

            let axpromise = _this.axios.post(_this.api, postData, {
              headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              }
            }).then(response => {

            let rt = response.data
            if (response.status === 200) {

              if (rt.status === 'success') {
                if (rt.credentials) {
                  _this.new_account_created = true
                  _this.new_password = rt.credentials
                }

                const toast = useToast()
                toast.success("A new patient was added successfully.")
              } else {
                _this.error_registration = true
                if (rt.info) {
                  _this.send_error_email(postData['patient_email'], rt.info)
                } else {
                  _this.send_error_email(postData['patient_email'], "")
                }
                console.error(rt)
              }

            }

            Promise.all([cpromise, axpromise])

            _this.registering = false
            _this.loading = true
            })

          })
        } else {
          alert("You have to agree with the terms and conditions!") //this should never run
        }
      },
      send_error_email(rt) {
        let uid = localStorage.getItem('uid')
        let issue = {
          "BV_AUTO_ISSUE": 
          { 
            url: "/add_patient api", 
            uid: uid, 
            message: "AUTOGENERATED ISSUE: \n\nFailed to add patient: " + this.do_not_use_email ? this.account_auth_id: this.email + " \n\n" + rt
          }
        }

        let pointer = 'Issues/' + new Date().toDateString()
        updateDataDirectory(pointer, issue)
      },
      closeRegistration() {
        this.registering = true
        this.new_account_created = false
        this.error_registration = false
        this.agreement = false
        this.firstname = ''
        this.surname = ''
        this.email = ''
        this.password = ''
        this.do_not_use_email = false
        this.selected_comm_difficulties = []

        this.$formkit.reset("formPatient")

        this.patient_has_account = false
        this.loading = false
        this.$emit('createNewClient');
      },
      confirm_no_email() {
        if (!this.do_not_use_email) {
          this.generateUsernames()
          this.no_email_confirmation_modal = new Modal(document.getElementById('no_email_confirmation_modal'))
          this.no_email_confirmation_modal.show()
        }
      },
      generateUsernames() {

        function generateRandomChar() {
            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            return chars.charAt(Math.floor(Math.random() * chars.length));
        }

        // Get the full firstname and surname
        const fullFirstName = this.firstname.toUpperCase();
        const fullSurname = this.surname.toUpperCase();

        // Generate 4 random alphanumeric characters for uniqueness
        let randomPart = '';
        for (let i = 0; i < 4; i++) {
            randomPart += generateRandomChar();
        }

        this.uniqueId_name = (fullFirstName + fullSurname + randomPart).substring(0, 12).replace(/\s+/g, '');

        this.account_auth_id = this.uniqueId_name

        const fruits = [
            'Apple', 'Banana', 'Cherry', 'Date', 'Elderberry', 'Fig', 'Grape', 
            'Honeydew', 'Icedfruit', 'Jackfruit', 'Kiwi', 'Lemon', 'Mango', 
            'Nectarine', 'Olive', 'Papaya', 'Quince', 'Raspberry', 'Strawberry', 
            'Tangerine', 'Uva', 'Watermelon'
        ];

        const randomFruit = fruits[Math.floor(Math.random() * fruits.length)];

        const fruitPart = randomFruit.toUpperCase();

        randomPart = Math.random().toString(36).substring(2, 6).toUpperCase();

        this.uniqueId_fruit = fruitPart + randomPart;
      }
    }
}
</script>

<style scoped>
</style>